import { LitElement, html, css } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { Ref, createRef, ref } from 'lit/directives/ref.js';
import { map } from 'lit/directives/map.js';
import { when } from 'lit/directives/when.js';
import { choose } from 'lit/directives/choose.js';
import { classMap } from 'lit/directives/class-map.js';

import { makeCreateUserRequest } from '../form-util';
import { baseStyles } from '../styles';
import { dialogStyles } from './dialog-styles';
import { countries } from '../../model/countries';
import { UserStatus } from '../../model/UserStatus';
import {
  RegistrationController,
  OktaUserStatus,
} from '../controllers/RegistrationController';
import { validateCreateUserRequest } from '../form-util';

@customElement('registration-dialog')
export class RegistrationDialog extends LitElement {
  static styles = [
    baseStyles,
    dialogStyles,
    css`
      input,
      select {
        margin-top: 0.25rem;
      }

      form.submitted input:invalid,
      form.submitted select:invalid {
        outline-color: red;
        outline-style: ridge;
        outline-width: 2px;
      }

      input[type='text']:focus,
      input[type='email']:focus,
      select:focus {
        outline-color: #0164ad;
        outline-style: ridge;
        outline-width: 2px;
      }

      label {
        margin-bottom: 1rem;
      }

      p {
        font-family: GT-Walsheim-Pro;
        margin-bottom: 1rem;
      }

      p + p {
        margin-top: 1rem;
      }

      form {
        margin-top: 1rem;
      }

      #footer {
        display: flex;
        align-items: baseline;
        gap: 0.5rem;
        margin-top: 0;
      }

      #errors {
        font-size: 1.125rem;
        color: red;
        margin-top: 0;
      }
    `,
  ];

  private registrationController: RegistrationController =
    new RegistrationController(this, (e) => this.dispatchSrpError(e));
  dialogRef: Ref<HTMLDialogElement> = createRef();
  formRef: Ref<HTMLFormElement> = createRef();

  @state()
  inProgress: boolean = false;

  @state()
  oktaUserStatus?: OktaUserStatus;

  @state()
  errors: string[] = [];

  open() {
    this.dialogRef.value?.showModal();
  }

  close() {
    this.dialogRef.value?.close();
    this.oktaUserStatus = undefined;
  }

  dispatchSrpError(e: Error) {
    this.dispatchEvent(
      new CustomEvent('srp-error', { detail: e, bubbles: true, composed: true })
    );
  }

  async onSubmit(e: Event) {
    e.preventDefault();
    this.formRef.value?.classList.toggle('submitted', true);
    this.inProgress = true;
    const user = makeCreateUserRequest(new FormData(this.formRef.value));
    this.errors = validateCreateUserRequest(user);
    if (this.errors.length == 0) {
      this.oktaUserStatus = await this.registrationController.register(user);
    }
    this.inProgress = false;
  }

  async onResend() {
    this.inProgress = true;
    if (this.registrationController.user?.email === undefined) {
      console.error('this.registrationController.user.email is undefined');
    } else {
      await this.registrationController.reactivateUser(
        this.registrationController.user.email
      );
      this.oktaUserStatus = 'REACTIVATED';
    }
    this.inProgress = false;
  }

  postRegistration() {
    return html`
      <p>
        Thank you for submitting your registration. You will shortly receive an
        email at
        <strong>${this.registrationController.user?.email}</strong> with an
        activation link to set up a password for your Cirrus Logic account.
      </p>
      <p>
        Once the account is activated, you can return to this page to sign in
        and download files.
      </p>
      <button type="button" @click=${() => this.close()}>Close</button>
    `;
  }

  postReactivation() {
    return html`
      <p>
        The activation link for
        <strong>${this.registrationController.user?.email}</strong> has been
        resent.
      </p>
      <button type="button" @click=${() => this.close()}>Close</button>
    `;
  }

  registrationForm() {
    return html`
      <h2>Registration Form</h2>
      <form ${ref(this.formRef)}>
        <label for="firstname">
          First Name
          <input
            type="text"
            id="firstname"
            name="firstname"
            autocomplete="given-name"
            required
          />
        </label>

        <label for="secondname">
          Second Name
          <input
            type="text"
            id="secondname"
            name="secondname"
            autocomplete="family-name"
            required
          />
        </label>

        <label for="email">
          Email Address
          <input
            type="email"
            id="email"
            name="email"
            autocomplete="email"
            required
            pattern="^[w-.]+@([w-]+.)+[w-]{2,4}$"
          />
        </label>

        <label for="organization">
          Organization
          <input
            type="text"
            id="organization"
            name="organization"
            autocomplete="organization"
            required
          />
        </label>

        <label for="role">
          Role
          <input
            type="text"
            id="role"
            name="role"
            autocomplete="organization-title"
            required
          />
        </label>

        <label for="application">
          Application Area
          <select id="application" name="application" required>
            <option value="">--- Select an option ---</option>
            <option value="Smartphone">Smartphone</option>
            <option value="Automotive">Automotive</option>
            <option value="Imaging">Imaging</option>
            <option value="Pro Audio">Pro Audio</option>
            <option value="Computer">Computer</option>
            <option value="Home Audio">Home Audio</option>
            <option value="Audio">Audio</option>
            <option value="Headset">Headset</option>
            <option value="Industrial">Industrial</option>
            <option value="Wearables">Wearables</option>
            <option value="Portable Audio">Portable Audio</option>
            <option value="Smarthome">Smarthome</option>
            <option value="DSC/DVC">DSC/DVC</option>
            <option value="Gaming">Gaming</option>
            <option value="Musical Instruments">Musical Instruments</option>
            <option value="AVR/HTiB">AVR/HTiB</option>
            <option value="Medical">Medical</option>
            <option value="Energy">Energy</option>
            <option value="Home Appliances">Home Appliances</option>
            <option value="Tablet">Tablet</option>
          </select>
        </label>

        <label for="country">
          Country
          <select id="country" name="country" required>
            <option value="">--- Select an option ---</option>
            ${map(
              countries,
              (c) => html` <option value=${c.code}>${c.name}</option> `
            )}
          </select>
        </label>

        <p id="disclaimer">
          Personal data collected as part of your registration will only be used
          in connection with managing access to, and the distribution of, our
          software. We may also share personal data collected as part of the
          registration process with our third-party service providers, for the
          purpose of enabling them to perform services on our behalf, in
          accordance with our instructions.
        </p>
        <p>
          Please refer to our
          <a href="https://www.cirrus.com/legal/privacy/" target="_blank"
            >Privacy Policy</a
          >
          for further information.
        </p>

        <div id="footer">
          <button
            type="button"
            @click=${() => this.close()}
            ?disabled=${this.inProgress}
          >
            Cancel
          </button>
          <button
            type="submit"
            @click=${(e: Event) => this.onSubmit(e)}
            ?disabled=${this.inProgress}
          >
            Submit
          </button>
          ${when(
            this.errors.length > 0,
            () => html` <span id="errors">${this.errors.join('\n')}</span> `
          )}
        </div>
      </form>
    `;
  }

  passwordResetPrompt() {
    return html`
      <p>
        It appears that an account for
        <strong>${this.registrationController.user?.email}</strong> already
        exists. You are welcome to try and reset the password for this account
        using
        <a href="https://cirrus.okta.com/signin/forgot-password" target="_blank"
          >this link</a
        >.
      </p>
      <button type="button" @click=${() => this.close()}>Close</button>
    `;
  }

  resendActivationPrompt() {
    return html`
      <p>
        It appears that an account for
        <strong>${this.registrationController.user?.email}</strong> already
        exists, but it has not yet been activated. If this email address belongs
        to you, you are welcome to resend the activation email using the button
        below.
      </p>
      <button type="button" @click=${() => this.close()}>Close</button>
      <button type="button" @click=${() => this.onResend()}>Resend</button>
    `;
  }

  render() {
    return html`
      <dialog
        id="registrationDialog"
        ${ref(this.dialogRef)}
        class=${classMap({ 'cursor-progress': this.inProgress })}
      >
        ${choose(
          this.oktaUserStatus,
          [
            [UserStatus.ACTIVE, () => this.passwordResetPrompt()],
            [UserStatus.PROVISIONED, () => this.resendActivationPrompt()],
            ['REACTIVATED', () => this.postReactivation()],
            ['CREATED', () => this.postRegistration()],
          ],
          () => this.registrationForm()
        )}
      </dialog>
    `;
  }
}

import { ReactiveController, ReactiveControllerHost } from 'lit';

import { createUser, activate } from '../http';
import { CreateUserRequest } from '../../model/CreateUserRequest';
import { UserStatus } from '../../model/UserStatus';

export type OktaUserStatus = UserStatus | 'CREATED' | 'REACTIVATED';

export class RegistrationController implements ReactiveController {
  host: ReactiveControllerHost;

  errorCallback: (e: Error) => void;

  user?: CreateUserRequest;

  hostConnected() {}

  constructor(host: ReactiveControllerHost, errorCallback: (e: Error) => void) {
    this.errorCallback = errorCallback;
    (this.host = host).addController(this);
  }

  async register(user: CreateUserRequest) {
    try {
      this.user = user;
      return await createUser(user);
    } catch (e) {
      this.errorCallback(e as Error);
    }
  }

  async reactivateUser(userId: string) {
    try {
      await activate(userId);
    } catch (e) {
      this.errorCallback(e as Error);
    }
  }
}

import { css } from 'lit';
export const baseStyles = css`
@charset "UTF-8";
/*-------------------------------------------
    Grid Settings for Neat Grids
    http://neat.bourbon.io

    The neat documentation recommends a dedicated grid settings file for
    grid configuration.
-------------------------------------------*/
html {
  box-sizing: border-box;
}

*, *::after, *::before {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  background: #ffffff;
  -webkit-font-smoothing: antialiased;
}

*, *:before, *:after {
  box-sizing: inherit;
}

.ts-detail {
  text-shadow: 0px 0px 21px rgba(0, 0, 0, 0.99);
}

.ts-detail-force {
  text-shadow: 0px 0px 21px rgba(0, 0, 0, 0.99) !important;
}

.ts-broad {
  text-shadow: 0px 0px 63px rgba(0, 0, 0, 0.5);
}

.ts-broad-force {
  text-shadow: 0px 0px 63px rgba(0, 0, 0, 0.5) !important;
}

.no-shadows .ts-detail, .no-shadows .ts-broad {
  text-shadow: none;
}

audio,
canvas,
img,
svg,
video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.cf:after {
  content: "";
  display: table;
  clear: both;
}

img.lazy {
  display: none;
}

.lazy-hidden {
  opacity: 0;
}

.lazy-loaded {
  transition: opacity 0.3s;
  opacity: 1;
}

* + * {
  margin-top: 1.25rem;
}
@media screen and (min-width: 59.375em) {
  * + * {
    margin-top: 1.875rem;
  }
}
@media screen and (min-width: 87.5em) {
  * + * {
    margin-top: 1.875rem;
  }
}

.spacing > * + * {
  margin-top: 1.25rem;
}
@media screen and (min-width: 59.375em) {
  .spacing > * + * {
    margin-top: 1.875rem;
  }
}
@media screen and (min-width: 87.5em) {
  .spacing > * + * {
    margin-top: 1.875rem;
  }
}

.spacing-quarter > * + * {
  margin-top: 0.3125rem;
}
@media screen and (min-width: 59.375em) {
  .spacing-quarter > * + * {
    margin-top: 0.46875rem;
  }
}
@media screen and (min-width: 87.5em) {
  .spacing-quarter > * + * {
    margin-top: 0.46875rem;
  }
}

.spacing-half > * + * {
  margin-top: 0.625rem;
}
@media screen and (min-width: 59.375em) {
  .spacing-half > * + * {
    margin-top: 0.9375rem;
  }
}
@media screen and (min-width: 87.5em) {
  .spacing-half > * + * {
    margin-top: 0.9375rem;
  }
}

.spacing-one-and-half > * + * {
  margin-top: 1.875rem;
}
@media screen and (min-width: 59.375em) {
  .spacing-one-and-half > * + * {
    margin-top: 2.8125rem;
  }
}
@media screen and (min-width: 87.5em) {
  .spacing-one-and-half > * + * {
    margin-top: 2.8125rem;
  }
}

.spacing-double > * + * {
  margin-top: 2.5rem;
}
@media screen and (min-width: 59.375em) {
  .spacing-double > * + * {
    margin-top: 3.75rem;
  }
}
@media screen and (min-width: 87.5em) {
  .spacing-double > * + * {
    margin-top: 3.75rem;
  }
}

.spacing-triple > * + * {
  margin-top: 2.5rem;
}
@media screen and (min-width: 59.375em) {
  .spacing-triple > * + * {
    margin-top: 5.625rem;
  }
}
@media screen and (min-width: 87.5em) {
  .spacing-triple > * + * {
    margin-top: 5.625rem;
  }
}

.spacing-zero > * + * {
  margin-top: 0;
}

.spacing-top > *:first-child {
  margin-top: 1.25rem;
}
@media screen and (min-width: 59.375em) {
  .spacing-top > *:first-child {
    margin-top: 1.875rem;
  }
}
@media screen and (min-width: 87.5em) {
  .spacing-top > *:first-child {
    margin-top: 1.875rem;
  }
}

.spacing-top-double > *:first-child {
  margin-top: 2.5rem;
}
@media screen and (min-width: 59.375em) {
  .spacing-top-double > *:first-child {
    margin-top: 3.75rem;
  }
}
@media screen and (min-width: 87.5em) {
  .spacing-top-double > *:first-child {
    margin-top: 3.75rem;
  }
}

*.spacing-top-none {
  margin-top: 0 !important;
}

@media print {
  .print-hide {
    display: none !important;
  }
}

@media print {
  .print-show {
    display: block !important;
    margin: auto !important;
    width: auto !important;
    height: auto !important;
    overflow: visible !important;
    position: static !important;
    clip: auto !important;
  }
}

.show--xsmall-only {
  display: none !important;
}
@media screen and (min-width: 18.75em) {
  .show--xsmall-only {
    display: block !important;
  }
}
@media screen and (min-width: 25em) {
  .show--xsmall-only {
    display: none !important;
  }
}
.show--xsmall-large-mid {
  display: none !important;
}
@media screen and (min-width: 18.75em) {
  .show--xsmall-large-mid {
    display: block !important;
  }
}
@media screen and (min-width: 53.125em) {
  .show--xsmall-large-mid {
    display: none !important;
  }
}
.show--small {
  display: none !important;
}
@media screen and (min-width: 25em) {
  .show--small {
    display: block !important;
  }
}
.show--small-large {
  display: none !important;
}
@media screen and (min-width: 25em) {
  .show--small-large {
    display: block !important;
  }
}
@media screen and (min-width: 46.875em) {
  .show--small-large {
    display: none !important;
  }
}
.show--medium {
  display: none !important;
}
@media screen and (min-width: 31.25em) {
  .show--medium {
    display: block !important;
  }
}
.show--medium-mid {
  display: none !important;
}
@media screen and (min-width: 39.0625em) {
  .show--medium-mid {
    display: block !important;
  }
}
.show--large {
  display: none !important;
}
@media screen and (min-width: 46.875em) {
  .show--large {
    display: block !important;
  }
}
.show--large-only {
  display: none !important;
}
@media screen and (min-width: 46.875em) {
  .show--large-only {
    display: block !important;
  }
}
@media screen and (min-width: 59.375em) {
  .show--large-only {
    display: none !important;
  }
}
.show--large-mid {
  display: none !important;
}
@media screen and (min-width: 53.125em) {
  .show--large-mid {
    display: block !important;
  }
}
.show--xlarge {
  display: none !important;
}
@media screen and (min-width: 59.375em) {
  .show--xlarge {
    display: block !important;
  }
}
.show--xlarge-only {
  display: none !important;
}
@media screen and (min-width: 59.375em) {
  .show--xlarge-only {
    display: block !important;
  }
}
@media screen and (min-width: 68.75em) {
  .show--xlarge-only {
    display: none !important;
  }
}
.show--xlarge-inline {
  display: none !important;
}
@media screen and (min-width: 59.375em) {
  .show--xlarge-inline {
    display: inline !important;
  }
}
.show--xxlarge {
  display: none !important;
}
@media screen and (min-width: 68.75em) {
  .show--xxlarge {
    display: block !important;
  }
}
.show--xxxlarge {
  display: none !important;
}
@media screen and (min-width: 81.25em) {
  .show--xxxlarge {
    display: block !important;
  }
}

.hide {
  display: none !important;
}
.hide--small {
  display: block !important;
}
@media screen and (min-width: 25em) {
  .hide--small {
    display: none !important;
  }
}
.hide--medium {
  display: block !important;
}
@media screen and (min-width: 31.25em) {
  .hide--medium {
    display: none !important;
  }
}
.hide--medium-mid {
  display: block !important;
}
@media screen and (min-width: 39.0625em) {
  .hide--medium-mid {
    display: none !important;
  }
}
.hide--large {
  display: block !important;
}
@media screen and (min-width: 46.875em) {
  .hide--large {
    display: none !important;
  }
}
.hide--xlarge {
  display: block !important;
}
@media screen and (min-width: 59.375em) {
  .hide--xlarge {
    display: none !important;
  }
}
.hide--xlarge-inline {
  display: inline !important;
}
@media screen and (min-width: 59.375em) {
  .hide--xlarge-inline {
    display: none !important;
  }
}
.hide--xxlarge {
  display: block !important;
}
@media screen and (min-width: 68.75em) {
  .hide--xxlarge {
    display: none !important;
  }
}
.hide--xxxlarge {
  display: block !important;
}
@media screen and (min-width: 81.25em) {
  .hide--xxxlarge {
    display: none !important;
  }
}

.wow {
  visibility: hidden;
}

.page-canvas {
  margin-top: 0;
}

.nonbreak {
  white-space: nowrap;
}

.acsb-widget.acsb-active * + * {
  margin-top: 0 !important;
}
.acsb-widget.acsb-active .acsb-hero .acsb-hero-actions .acsb-hero-action {
  margin-top: 1.25rem !important;
}
@media screen and (min-width: 59.375em) {
  .acsb-widget.acsb-active .acsb-hero .acsb-hero-actions .acsb-hero-action {
    margin-top: 1.875rem !important;
  }
}
@media screen and (min-width: 87.5em) {
  .acsb-widget.acsb-active .acsb-hero .acsb-hero-actions .acsb-hero-action {
    margin-top: 1.875rem !important;
  }
}
.acsb-widget.acsb-active .acsb-main-options .acsb-actions .acsb-actions-group .acsb-action-box .acsb-big-box-element {
  margin-top: 1.25rem !important;
}
@media screen and (min-width: 59.375em) {
  .acsb-widget.acsb-active .acsb-main-options .acsb-actions .acsb-actions-group .acsb-action-box .acsb-big-box-element {
    margin-top: 1.875rem !important;
  }
}
@media screen and (min-width: 87.5em) {
  .acsb-widget.acsb-active .acsb-main-options .acsb-actions .acsb-actions-group .acsb-action-box .acsb-big-box-element {
    margin-top: 1.875rem !important;
  }
}

@font-face {
  font-family: "GT-Walsheim-Pro";
  src: url("/fonts/GT-Walsheim-Pro-Regular.eot");
  src: url("/fonts/GT-Walsheim-Pro-Regular.eot?#iefix") format("embedded-opentype"), url("/fonts/GT-Walsheim-Pro-Regular.woff") format("woff"), url("/fonts/GT-Walsheim-Pro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "GT-Walsheim-Pro";
  src: url("/fonts/GT-Walsheim-Pro-Bold.eot");
  src: url("/fonts/GT-Walsheim-Pro-Bold.eot?#iefix") format("embedded-opentype"), url("/fonts/GT-Walsheim-Pro-Bold.woff") format("woff"), url("/fonts/GT-Walsheim-Pro-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "GT-Walsheim-Pro";
  src: url("/fonts/GT-Walsheim-Pro-Bold-Oblique.eot");
  src: url("/fonts/GT-Walsheim-Pro-Bold-Oblique.eot?#iefix") format("embedded-opentype"), url("/fonts/GT-Walsheim-Pro-Bold-Oblique.woff") format("woff"), url("/fonts/GT-Walsheim-Pro-Bold-Oblique.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "GT-Walsheim-Pro";
  src: url("/fonts/GT-Walsheim-Pro-Regular-Oblique.eot");
  src: url("/fonts/GT-Walsheim-Pro-Regular-Oblique.eot?#iefix") format("embedded-opentype"), url("/fonts/GT-Walsheim-Pro-Regular-Oblique.woff") format("woff"), url("/fonts/GT-Walsheim-Pro-Regular-Oblique.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Local-GT-Walsheim-Pro";
  src: local("GT-Walsheim-Pro-Regular");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Local-GT-Walsheim-Pro";
  src: local("GT-Walsheim-Pro-Bold");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Local-GT-Walsheim-Pro";
  src: local("GT-Walsheim-Pro-Bold-Oblique");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Local-GT-Walsheim-Pro";
  src: local("GT-Walsheim-Pro-Regular-Oblique");
  font-weight: normal;
  font-style: italic;
}
html {
  color: #282725;
}

body {
  font-family: Local-GT-Walsheim-Pro, Helvetica, Arial, sans-serif;
  opacity: 1;
}
html.fonts-loaded body {
  font-family: GT-Walsheim-Pro, Helvetica, Arial, sans-serif;
  transition: opacity 0.1s ease-out;
  opacity: 1;
}
body * {
  hyphens: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

p {
  margin: 0;
}

ul, ol {
  margin: 0;
  padding: 0;
}
ul li, ol li {
  list-style: none;
  padding: 0;
  margin: 0;
}

hr {
  height: 0;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

b {
  font-weight: normal;
}

i {
  font-style: none;
}

q:before {
  content: "“";
}
q:after {
  content: "”";
}
q q:before {
  content: "‘";
}
q q:after {
  content: "’";
}

cite {
  font-style: normal;
}

.alphalarge {
  font-size: 2.5rem;
  line-height: 1;
  letter-spacing: -0.01em;
}
@media screen and (min-width: 59.375em) {
  .alphalarge {
    font-size: 6.875rem;
    line-height: 1;
  }
}
@media screen and (min-width: 87.5em) {
  .alphalarge {
    font-size: 6.875rem;
    line-height: 1;
  }
}
@media print {
  .alphalarge {
    font-size: 2.5rem;
    line-height: 1;
  }
}

.alpha {
  font-size: 2.5rem;
  line-height: 1;
  letter-spacing: -0.01em;
}
@media screen and (min-width: 59.375em) {
  .alpha {
    font-size: 5rem;
    line-height: 1;
  }
}
@media screen and (min-width: 87.5em) {
  .alpha {
    font-size: 5rem;
    line-height: 1;
  }
}
@media print {
  .alpha {
    font-size: 2.5rem;
    line-height: 1;
  }
}

.alphasmall {
  font-size: 1.75rem;
  line-height: 1.0714285714;
  letter-spacing: -0.01em;
}
@media screen and (min-width: 59.375em) {
  .alphasmall {
    font-size: 3.75rem;
    line-height: 1.0666666667;
  }
}
@media screen and (min-width: 87.5em) {
  .alphasmall {
    font-size: 3.75rem;
    line-height: 1.0666666667;
  }
}
@media print {
  .alphasmall {
    font-size: 2.5rem;
    line-height: 1;
  }
}

.beta {
  font-size: 1.75rem;
  line-height: 1.0714285714;
}
@media screen and (min-width: 59.375em) {
  .beta {
    font-size: 2rem;
    line-height: 1.3125;
  }
}
@media screen and (min-width: 87.5em) {
  .beta {
    font-size: 2rem;
    line-height: 1.3125;
  }
}
@media print {
  .beta {
    font-size: 1.75rem;
    line-height: 1.0714285714;
  }
}

.gamma {
  font-size: 1.125rem;
  line-height: 1.2777777778;
}
@media screen and (min-width: 59.375em) {
  .gamma {
    font-size: 1.5rem;
    line-height: 1.4166666667;
  }
}
@media screen and (min-width: 87.5em) {
  .gamma {
    font-size: 1.5rem;
    line-height: 1.4166666667;
  }
}
@media print {
  .gamma {
    font-size: 1.125rem;
    line-height: 1.2777777778;
  }
}

.delta {
  font-size: 1.125rem;
  line-height: 1.2777777778;
}
@media screen and (min-width: 59.375em) {
  .delta {
    font-size: 1.5rem;
    line-height: 1.4166666667;
  }
}
@media screen and (min-width: 87.5em) {
  .delta {
    font-size: 1.5rem;
    line-height: 1.4166666667;
  }
}
@media print {
  .delta {
    font-size: 1.125rem;
    line-height: 1.2777777778;
  }
}

.epsilon {
  font-size: 0.875rem;
  line-height: 1.3571428571;
}
@media screen and (min-width: 59.375em) {
  .epsilon {
    font-size: 1.125rem;
    line-height: 1.3333333333;
  }
}
@media screen and (min-width: 87.5em) {
  .epsilon {
    font-size: 1.125rem;
    line-height: 1.3333333333;
  }
}
@media print {
  .epsilon {
    font-size: 0.875rem;
    line-height: 1.3571428571;
  }
}

.zeta {
  font-size: 0.75rem;
  line-height: 1.4166666667;
}
@media screen and (min-width: 59.375em) {
  .zeta {
    font-size: 0.9375rem;
    line-height: 1.6;
  }
}
@media screen and (min-width: 87.5em) {
  .zeta {
    font-size: 0.9375rem;
    line-height: 1.6;
  }
}
@media print {
  .zeta {
    font-size: 0.9375rem;
    line-height: 1.6;
  }
}

.eta {
  font-size: 0.625rem;
  line-height: 1.2;
  letter-spacing: 0.05em;
}
@media screen and (min-width: 59.375em) {
  .eta {
    font-size: 0.875rem;
    line-height: 1.2857142857;
  }
}
@media screen and (min-width: 87.5em) {
  .eta {
    font-size: 0.875rem;
    line-height: 1.2857142857;
  }
}
@media print {
  .eta {
    font-size: 0.625rem;
    line-height: 2.4;
  }
}

.theta {
  font-size: 1.4375rem;
  line-height: 1.2173913043;
}
@media screen and (min-width: 59.375em) {
  .theta {
    font-size: 2.5rem;
    line-height: 1.15;
  }
}
@media screen and (min-width: 87.5em) {
  .theta {
    font-size: 2.5rem;
    line-height: 1.15;
  }
}
@media print {
  .theta {
    font-size: 2.5rem;
    line-height: 1.15;
  }
}

.iota {
  font-size: 1.25rem;
  line-height: 1.05;
}
@media screen and (min-width: 59.375em) {
  .iota {
    font-size: 2.5rem;
    line-height: 1.05;
  }
}
@media screen and (min-width: 87.5em) {
  .iota {
    font-size: 2.5rem;
    line-height: 1.05;
  }
}
@media print {
  .iota {
    font-size: 1.25rem;
    line-height: 1.05;
  }
}

.kappa {
  font-size: 1.4375rem;
  line-height: 1;
}
@media screen and (min-width: 59.375em) {
  .kappa {
    font-size: 2rem;
    line-height: 1;
  }
}
@media screen and (min-width: 87.5em) {
  .kappa {
    font-size: 2rem;
    line-height: 1;
  }
}
@media print {
  .kappa {
    font-size: 1.4375rem;
    line-height: 1;
  }
}

.lambda {
  font-size: 1.125rem;
  line-height: 1.1666666667;
}
@media screen and (min-width: 59.375em) {
  .lambda {
    font-size: 1.5rem;
    line-height: 1.3333333333;
  }
}
@media screen and (min-width: 87.5em) {
  .lambda {
    font-size: 1.5rem;
    line-height: 1.3333333333;
  }
}
@media print {
  .lambda {
    font-size: 1.125rem;
    line-height: 1.1666666667;
  }
}

.p, p {
  font-size: 0.9375rem;
  line-height: 1.4;
}
@media screen and (min-width: 59.375em) {
  .p, p {
    font-size: 1.125rem;
    line-height: 1.5555555556;
  }
}
@media screen and (min-width: 87.5em) {
  .p, p {
    font-size: 1.125rem;
    line-height: 1.5555555556;
  }
}
@media print {
  .p, p {
    font-size: 0.9375rem;
    line-height: 1.4;
  }
}

.intro, p.intro {
  font-size: 1.4375rem;
  line-height: 1.2173913043;
}
@media screen and (min-width: 59.375em) {
  .intro, p.intro {
    font-size: 1.5rem;
    line-height: 1.5;
  }
}
@media screen and (min-width: 87.5em) {
  .intro, p.intro {
    font-size: 1.5rem;
    line-height: 1.5;
  }
}
@media print {
  .intro, p.intro {
    font-size: 1.4375rem;
    line-height: 1.2173913043;
  }
}

.large, p.large {
  font-size: 1.25rem;
  line-height: 1.35;
}
@media screen and (min-width: 59.375em) {
  .large, p.large {
    font-size: 1.25rem;
    line-height: 1.5;
  }
}
@media screen and (min-width: 87.5em) {
  .large, p.large {
    font-size: 1.25rem;
    line-height: 1.5;
  }
}
@media print {
  .large, p.large {
    font-size: 1.25rem;
    line-height: 1.35;
  }
}

.small, p.small {
  font-size: 1rem;
  line-height: 1.3125;
}
@media screen and (min-width: 59.375em) {
  .small, p.small {
    font-size: 1.125rem;
    line-height: 1.3333333333;
  }
}
@media screen and (min-width: 87.5em) {
  .small, p.small {
    font-size: 1.125rem;
    line-height: 1.3333333333;
  }
}
@media print {
  .small, p.small {
    font-size: 1rem;
    line-height: 1.3125;
  }
}

.xsmall, p.xsmall {
  font-size: 0.75rem;
  line-height: 1.3333333333;
}
@media screen and (min-width: 59.375em) {
  .xsmall, p.xsmall {
    font-size: 0.9375rem;
    line-height: 1.3333333333;
  }
}
@media screen and (min-width: 87.5em) {
  .xsmall, p.xsmall {
    font-size: 0.9375rem;
    line-height: 1.3333333333;
  }
}
@media print {
  .xsmall, p.xsmall {
    font-size: 0.75rem;
    line-height: 1.3333333333;
  }
}

.blockquote {
  font-size: 1.125rem;
  line-height: 1.1666666667;
}
@media screen and (min-width: 59.375em) {
  .blockquote {
    font-size: 1.5rem;
    line-height: 1.3333333333;
  }
}
@media screen and (min-width: 87.5em) {
  .blockquote {
    font-size: 1.5rem;
    line-height: 1.3333333333;
  }
}
@media print {
  .blockquote {
    font-size: 1.125rem;
    line-height: 1.1666666667;
  }
}

ul {
  font-size: 0.9375rem;
  line-height: 1.4;
}
@media screen and (min-width: 59.375em) {
  ul {
    font-size: 1.125rem;
    line-height: 1.5555555556;
  }
}
@media screen and (min-width: 87.5em) {
  ul {
    font-size: 1.125rem;
    line-height: 1.5555555556;
  }
}
@media print {
  ul {
    font-size: 0.9375rem;
    line-height: 1.4;
  }
}

ol {
  font-size: 0.9375rem;
  line-height: 1.4;
}
@media screen and (min-width: 59.375em) {
  ol {
    font-size: 1.125rem;
    line-height: 1.5555555556;
  }
}
@media screen and (min-width: 87.5em) {
  ol {
    font-size: 1.125rem;
    line-height: 1.5555555556;
  }
}
@media print {
  ol {
    font-size: 0.9375rem;
    line-height: 1.4;
  }
}

dt {
  font-size: 0.9375rem;
  line-height: 1.4;
}
@media screen and (min-width: 59.375em) {
  dt {
    font-size: 1.125rem;
    line-height: 1.5555555556;
  }
}
@media screen and (min-width: 87.5em) {
  dt {
    font-size: 1.125rem;
    line-height: 1.5555555556;
  }
}
@media print {
  dt {
    font-size: 0.9375rem;
    line-height: 1.4;
  }
}

dd {
  font-size: 0.9375rem;
  line-height: 1.4;
}
@media screen and (min-width: 59.375em) {
  dd {
    font-size: 1.125rem;
    line-height: 1.5555555556;
  }
}
@media screen and (min-width: 87.5em) {
  dd {
    font-size: 1.125rem;
    line-height: 1.5555555556;
  }
}
@media print {
  dd {
    font-size: 0.9375rem;
    line-height: 1.4;
  }
}

.longform > h1 {
  font-size: 2.5rem;
  line-height: 1;
  letter-spacing: -0.01em;
}
@media screen and (min-width: 59.375em) {
  .longform > h1 {
    font-size: 5rem;
    line-height: 1;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > h1 {
    font-size: 5rem;
    line-height: 1;
  }
}
@media print {
  .longform > h1 {
    font-size: 2.5rem;
    line-height: 1;
  }
}
.longform > h2 {
  font-size: 1.75rem;
  line-height: 1.0714285714;
}
@media screen and (min-width: 59.375em) {
  .longform > h2 {
    font-size: 2rem;
    line-height: 1.3125;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > h2 {
    font-size: 2rem;
    line-height: 1.3125;
  }
}
@media print {
  .longform > h2 {
    font-size: 1.75rem;
    line-height: 1.0714285714;
  }
}
.longform > h3 {
  font-size: 1.125rem;
  line-height: 1.2777777778;
}
@media screen and (min-width: 59.375em) {
  .longform > h3 {
    font-size: 1.5rem;
    line-height: 1.4166666667;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > h3 {
    font-size: 1.5rem;
    line-height: 1.4166666667;
  }
}
@media print {
  .longform > h3 {
    font-size: 1.125rem;
    line-height: 1.2777777778;
  }
}
.longform > h4 {
  font-size: 1.125rem;
  line-height: 1.2777777778;
}
@media screen and (min-width: 59.375em) {
  .longform > h4 {
    font-size: 1.5rem;
    line-height: 1.4166666667;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > h4 {
    font-size: 1.5rem;
    line-height: 1.4166666667;
  }
}
@media print {
  .longform > h4 {
    font-size: 1.125rem;
    line-height: 1.2777777778;
  }
}
.longform > h5 {
  font-size: 0.875rem;
  line-height: 1.3571428571;
}
@media screen and (min-width: 59.375em) {
  .longform > h5 {
    font-size: 1.125rem;
    line-height: 1.3333333333;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > h5 {
    font-size: 1.125rem;
    line-height: 1.3333333333;
  }
}
@media print {
  .longform > h5 {
    font-size: 0.875rem;
    line-height: 1.3571428571;
  }
}
.longform > h6 {
  font-size: 0.75rem;
  line-height: 1.4166666667;
}
@media screen and (min-width: 59.375em) {
  .longform > h6 {
    font-size: 0.9375rem;
    line-height: 1.6;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > h6 {
    font-size: 0.9375rem;
    line-height: 1.6;
  }
}
@media print {
  .longform > h6 {
    font-size: 0.9375rem;
    line-height: 1.6;
  }
}
.longform > h6.theta {
  font-size: 1.4375rem;
  line-height: 1.2173913043;
}
@media screen and (min-width: 59.375em) {
  .longform > h6.theta {
    font-size: 2.5rem;
    line-height: 1.15;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > h6.theta {
    font-size: 2.5rem;
    line-height: 1.15;
  }
}
@media print {
  .longform > h6.theta {
    font-size: 2.5rem;
    line-height: 1.15;
  }
}
.longform > p.intro {
  font-size: 1.4375rem;
  line-height: 1.2173913043;
}
@media screen and (min-width: 59.375em) {
  .longform > p.intro {
    font-size: 1.5rem;
    line-height: 1.5;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > p.intro {
    font-size: 1.5rem;
    line-height: 1.5;
  }
}
@media print {
  .longform > p.intro {
    font-size: 1.4375rem;
    line-height: 1.2173913043;
  }
}
.longform > p.large {
  font-size: 1.25rem;
  line-height: 1.35;
}
@media screen and (min-width: 59.375em) {
  .longform > p.large {
    font-size: 1.25rem;
    line-height: 1.5;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > p.large {
    font-size: 1.25rem;
    line-height: 1.5;
  }
}
@media print {
  .longform > p.large {
    font-size: 1.25rem;
    line-height: 1.35;
  }
}
.longform > p.small {
  font-size: 1rem;
  line-height: 1.3125;
}
@media screen and (min-width: 59.375em) {
  .longform > p.small {
    font-size: 1.125rem;
    line-height: 1.3333333333;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > p.small {
    font-size: 1.125rem;
    line-height: 1.3333333333;
  }
}
@media print {
  .longform > p.small {
    font-size: 1rem;
    line-height: 1.3125;
  }
}
.longform > p.xsmall {
  font-size: 0.75rem;
  line-height: 1.3333333333;
}
@media screen and (min-width: 59.375em) {
  .longform > p.xsmall {
    font-size: 0.9375rem;
    line-height: 1.3333333333;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > p.xsmall {
    font-size: 0.9375rem;
    line-height: 1.3333333333;
  }
}
@media print {
  .longform > p.xsmall {
    font-size: 0.75rem;
    line-height: 1.3333333333;
  }
}
.longform > p {
  font-size: 1.25rem;
  line-height: 1.35;
}
@media screen and (min-width: 59.375em) {
  .longform > p {
    font-size: 1.25rem;
    line-height: 1.5;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > p {
    font-size: 1.25rem;
    line-height: 1.5;
  }
}
@media print {
  .longform > p {
    font-size: 1.25rem;
    line-height: 1.35;
  }
}
.longform > ul {
  font-size: 0.9375rem;
  line-height: 1.4;
}
@media screen and (min-width: 59.375em) {
  .longform > ul {
    font-size: 1.125rem;
    line-height: 1.5555555556;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > ul {
    font-size: 1.125rem;
    line-height: 1.5555555556;
  }
}
@media print {
  .longform > ul {
    font-size: 0.9375rem;
    line-height: 1.4;
  }
}
.longform > ol {
  font-size: 0.9375rem;
  line-height: 1.4;
}
@media screen and (min-width: 59.375em) {
  .longform > ol {
    font-size: 1.125rem;
    line-height: 1.5555555556;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > ol {
    font-size: 1.125rem;
    line-height: 1.5555555556;
  }
}
@media print {
  .longform > ol {
    font-size: 0.9375rem;
    line-height: 1.4;
  }
}
.longform > dt {
  font-size: 0.9375rem;
  line-height: 1.4;
}
@media screen and (min-width: 59.375em) {
  .longform > dt {
    font-size: 1.125rem;
    line-height: 1.5555555556;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > dt {
    font-size: 1.125rem;
    line-height: 1.5555555556;
  }
}
@media print {
  .longform > dt {
    font-size: 0.9375rem;
    line-height: 1.4;
  }
}
.longform > dd {
  font-size: 0.9375rem;
  line-height: 1.4;
}
@media screen and (min-width: 59.375em) {
  .longform > dd {
    font-size: 1.125rem;
    line-height: 1.5555555556;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > dd {
    font-size: 1.125rem;
    line-height: 1.5555555556;
  }
}
@media print {
  .longform > dd {
    font-size: 0.9375rem;
    line-height: 1.4;
  }
}
.longform > .p {
  font-size: 0.9375rem;
  line-height: 1.4;
}
@media screen and (min-width: 59.375em) {
  .longform > .p {
    font-size: 1.125rem;
    line-height: 1.5555555556;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > .p {
    font-size: 1.125rem;
    line-height: 1.5555555556;
  }
}
@media print {
  .longform > .p {
    font-size: 0.9375rem;
    line-height: 1.4;
  }
}
.longform > .intro {
  font-size: 1.4375rem;
  line-height: 1.2173913043;
}
@media screen and (min-width: 59.375em) {
  .longform > .intro {
    font-size: 1.5rem;
    line-height: 1.5;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > .intro {
    font-size: 1.5rem;
    line-height: 1.5;
  }
}
@media print {
  .longform > .intro {
    font-size: 1.4375rem;
    line-height: 1.2173913043;
  }
}
.longform > .large {
  font-size: 1.25rem;
  line-height: 1.35;
}
@media screen and (min-width: 59.375em) {
  .longform > .large {
    font-size: 1.25rem;
    line-height: 1.5;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > .large {
    font-size: 1.25rem;
    line-height: 1.5;
  }
}
@media print {
  .longform > .large {
    font-size: 1.25rem;
    line-height: 1.35;
  }
}
.longform > .small {
  font-size: 1rem;
  line-height: 1.3125;
}
@media screen and (min-width: 59.375em) {
  .longform > .small {
    font-size: 1.125rem;
    line-height: 1.3333333333;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > .small {
    font-size: 1.125rem;
    line-height: 1.3333333333;
  }
}
@media print {
  .longform > .small {
    font-size: 1rem;
    line-height: 1.3125;
  }
}
.longform > .xsmall {
  font-size: 0.75rem;
  line-height: 1.3333333333;
}
@media screen and (min-width: 59.375em) {
  .longform > .xsmall {
    font-size: 0.9375rem;
    line-height: 1.3333333333;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > .xsmall {
    font-size: 0.9375rem;
    line-height: 1.3333333333;
  }
}
@media print {
  .longform > .xsmall {
    font-size: 0.75rem;
    line-height: 1.3333333333;
  }
}
.longform > .blockquote {
  font-size: 1.125rem;
  line-height: 1.1666666667;
}
@media screen and (min-width: 59.375em) {
  .longform > .blockquote {
    font-size: 1.5rem;
    line-height: 1.3333333333;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > .blockquote {
    font-size: 1.5rem;
    line-height: 1.3333333333;
  }
}
@media print {
  .longform > .blockquote {
    font-size: 1.125rem;
    line-height: 1.1666666667;
  }
}
.longform > .alphalarge {
  font-size: 2.5rem;
  line-height: 1;
  letter-spacing: -0.01em;
}
@media screen and (min-width: 59.375em) {
  .longform > .alphalarge {
    font-size: 6.875rem;
    line-height: 1;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > .alphalarge {
    font-size: 6.875rem;
    line-height: 1;
  }
}
@media print {
  .longform > .alphalarge {
    font-size: 2.5rem;
    line-height: 1;
  }
}
.longform > .alpha {
  font-size: 2.5rem;
  line-height: 1;
  letter-spacing: -0.01em;
}
@media screen and (min-width: 59.375em) {
  .longform > .alpha {
    font-size: 5rem;
    line-height: 1;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > .alpha {
    font-size: 5rem;
    line-height: 1;
  }
}
@media print {
  .longform > .alpha {
    font-size: 2.5rem;
    line-height: 1;
  }
}
.longform > .alphasmall {
  font-size: 1.75rem;
  line-height: 1.0714285714;
  letter-spacing: -0.01em;
}
@media screen and (min-width: 59.375em) {
  .longform > .alphasmall {
    font-size: 3.75rem;
    line-height: 1.0666666667;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > .alphasmall {
    font-size: 3.75rem;
    line-height: 1.0666666667;
  }
}
@media print {
  .longform > .alphasmall {
    font-size: 2.5rem;
    line-height: 1;
  }
}
.longform > .beta {
  font-size: 1.75rem;
  line-height: 1.0714285714;
}
@media screen and (min-width: 59.375em) {
  .longform > .beta {
    font-size: 2rem;
    line-height: 1.3125;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > .beta {
    font-size: 2rem;
    line-height: 1.3125;
  }
}
@media print {
  .longform > .beta {
    font-size: 1.75rem;
    line-height: 1.0714285714;
  }
}
.longform > .gamma {
  font-size: 1.125rem;
  line-height: 1.2777777778;
}
@media screen and (min-width: 59.375em) {
  .longform > .gamma {
    font-size: 1.5rem;
    line-height: 1.4166666667;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > .gamma {
    font-size: 1.5rem;
    line-height: 1.4166666667;
  }
}
@media print {
  .longform > .gamma {
    font-size: 1.125rem;
    line-height: 1.2777777778;
  }
}
.longform > .delta {
  font-size: 1.125rem;
  line-height: 1.2777777778;
}
@media screen and (min-width: 59.375em) {
  .longform > .delta {
    font-size: 1.5rem;
    line-height: 1.4166666667;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > .delta {
    font-size: 1.5rem;
    line-height: 1.4166666667;
  }
}
@media print {
  .longform > .delta {
    font-size: 1.125rem;
    line-height: 1.2777777778;
  }
}
.longform > .epsilon {
  font-size: 0.875rem;
  line-height: 1.3571428571;
}
@media screen and (min-width: 59.375em) {
  .longform > .epsilon {
    font-size: 1.125rem;
    line-height: 1.3333333333;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > .epsilon {
    font-size: 1.125rem;
    line-height: 1.3333333333;
  }
}
@media print {
  .longform > .epsilon {
    font-size: 0.875rem;
    line-height: 1.3571428571;
  }
}
.longform > .zeta {
  font-size: 0.75rem;
  line-height: 1.4166666667;
}
@media screen and (min-width: 59.375em) {
  .longform > .zeta {
    font-size: 0.9375rem;
    line-height: 1.6;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > .zeta {
    font-size: 0.9375rem;
    line-height: 1.6;
  }
}
@media print {
  .longform > .zeta {
    font-size: 0.9375rem;
    line-height: 1.6;
  }
}
.longform > .eta {
  font-size: 0.625rem;
  line-height: 1.2;
  letter-spacing: 0.05em;
}
@media screen and (min-width: 59.375em) {
  .longform > .eta {
    font-size: 0.875rem;
    line-height: 1.2857142857;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > .eta {
    font-size: 0.875rem;
    line-height: 1.2857142857;
  }
}
@media print {
  .longform > .eta {
    font-size: 0.625rem;
    line-height: 2.4;
  }
}
.longform > .theta {
  font-size: 1.4375rem;
  line-height: 1.2173913043;
}
@media screen and (min-width: 59.375em) {
  .longform > .theta {
    font-size: 2.5rem;
    line-height: 1.15;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > .theta {
    font-size: 2.5rem;
    line-height: 1.15;
  }
}
@media print {
  .longform > .theta {
    font-size: 2.5rem;
    line-height: 1.15;
  }
}
.longform > .iota {
  font-size: 1.25rem;
  line-height: 1.05;
}
@media screen and (min-width: 59.375em) {
  .longform > .iota {
    font-size: 2.5rem;
    line-height: 1.05;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > .iota {
    font-size: 2.5rem;
    line-height: 1.05;
  }
}
@media print {
  .longform > .iota {
    font-size: 1.25rem;
    line-height: 1.05;
  }
}
.longform > .kappa {
  font-size: 1.4375rem;
  line-height: 1;
}
@media screen and (min-width: 59.375em) {
  .longform > .kappa {
    font-size: 2rem;
    line-height: 1;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > .kappa {
    font-size: 2rem;
    line-height: 1;
  }
}
@media print {
  .longform > .kappa {
    font-size: 1.4375rem;
    line-height: 1;
  }
}
.longform > .lambda {
  font-size: 1.125rem;
  line-height: 1.1666666667;
}
@media screen and (min-width: 59.375em) {
  .longform > .lambda {
    font-size: 1.5rem;
    line-height: 1.3333333333;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > .lambda {
    font-size: 1.5rem;
    line-height: 1.3333333333;
  }
}
@media print {
  .longform > .lambda {
    font-size: 1.125rem;
    line-height: 1.1666666667;
  }
}

p + p {
  margin-top: 1.3125rem;
}
@media screen and (min-width: 59.375em) {
  p + p {
    margin-top: 1.5rem;
  }
}
@media screen and (min-width: 87.5em) {
  p + p {
    margin-top: 1.5rem;
  }
}

.longform > p + *, .longform > ol + *, .longform > ul + * {
  margin-top: 1.3125rem;
}
@media screen and (min-width: 59.375em) {
  .longform > p + *, .longform > ol + *, .longform > ul + * {
    margin-top: 1.5rem;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > p + *, .longform > ol + *, .longform > ul + * {
    margin-top: 1.5rem;
  }
}
.longform > img + *, .longform > .video-feature + *, .longform > .slideshow + * {
  margin-top: 2.625rem;
}
@media screen and (min-width: 59.375em) {
  .longform > img + *, .longform > .video-feature + *, .longform > .slideshow + * {
    margin-top: 3rem;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > img + *, .longform > .video-feature + *, .longform > .slideshow + * {
    margin-top: 3rem;
  }
}
.longform > p + p {
  margin-top: 0;
}
.longform > h1 + * {
  margin-top: 1.3125rem;
}
@media screen and (min-width: 59.375em) {
  .longform > h1 + * {
    margin-top: 1.5rem;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > h1 + * {
    margin-top: 1.5rem;
  }
}
.longform > h2 + * {
  margin-top: 0.65625rem;
}
@media screen and (min-width: 59.375em) {
  .longform > h2 + * {
    margin-top: 0.75rem;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > h2 + * {
    margin-top: 0.75rem;
  }
}
.longform > h3 + * {
  margin-top: 0.65625rem;
}
@media screen and (min-width: 59.375em) {
  .longform > h3 + * {
    margin-top: 0.75rem;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > h3 + * {
    margin-top: 0.75rem;
  }
}
.longform > h4 + * {
  margin-top: 0.65625rem;
}
@media screen and (min-width: 59.375em) {
  .longform > h4 + * {
    margin-top: 0.75rem;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > h4 + * {
    margin-top: 0.75rem;
  }
}
.longform > h5 + * {
  margin-top: 0.65625rem;
}
@media screen and (min-width: 59.375em) {
  .longform > h5 + * {
    margin-top: 0.75rem;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > h5 + * {
    margin-top: 0.75rem;
  }
}
.longform > h6 + * {
  margin-top: 0.65625rem;
}
@media screen and (min-width: 59.375em) {
  .longform > h6 + * {
    margin-top: 0.75rem;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > h6 + * {
    margin-top: 0.75rem;
  }
}
.longform > * + h1, .longform > * + h2, .longform > * + h3, .longform > * + h4, .longform > * + h5, .longform > * + h6, .longform > * + img, .longform > * + .video-feature, .longform > * + .slideshow {
  margin-top: 2.625rem;
}
@media screen and (min-width: 59.375em) {
  .longform > * + h1, .longform > * + h2, .longform > * + h3, .longform > * + h4, .longform > * + h5, .longform > * + h6, .longform > * + img, .longform > * + .video-feature, .longform > * + .slideshow {
    margin-top: 3rem;
  }
}
@media screen and (min-width: 87.5em) {
  .longform > * + h1, .longform > * + h2, .longform > * + h3, .longform > * + h4, .longform > * + h5, .longform > * + h6, .longform > * + img, .longform > * + .video-feature, .longform > * + .slideshow {
    margin-top: 3rem;
  }
}

.font-primary {
  font-family: Local-GT-Walsheim-Pro, Helvetica, Arial, sans-serif;
}
html.fonts-loaded .font-primary {
  font-family: GT-Walsheim-Pro, Helvetica, Arial, sans-serif;
}

.font-secondary {
  font-family: Georgia, Times New Roman, serif;
}

.sans-serif {
  font-family: Local-GT-Walsheim-Pro, Helvetica, Arial, sans-serif;
}
html.fonts-loaded .sans-serif {
  font-family: GT-Walsheim-Pro, Helvetica, Arial, sans-serif;
}

.serif {
  font-family: Georgia, Times New Roman, serif;
}

.monospace {
  font-family: Source Code Pro, Consolas, Liberation Mono, Menlo, Courier, monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
.alpha,
.beta,
.gamma,
.delta,
.epsilon,
.zeta {
  hyphens: none;
}

.alphalarge,
.alpha,
.alphasmall {
  text-transform: normal;
  letter-spacing: -0.01em;
  font-weight: bold;
  font-style: italic;
  font-family: Local-GT-Walsheim-Pro, Helvetica, Arial, sans-serif;
}
html.fonts-loaded .alphalarge,
html.fonts-loaded .alpha,
html.fonts-loaded .alphasmall {
  font-family: GT-Walsheim-Pro, Helvetica, Arial, sans-serif;
}

.lambda {
  text-transform: normal;
  letter-spacing: normal;
  font-weight: bold;
  font-style: italic;
  font-family: Local-GT-Walsheim-Pro, Helvetica, Arial, sans-serif;
}
html.fonts-loaded .lambda {
  font-family: GT-Walsheim-Pro, Helvetica, Arial, sans-serif;
}

.beta,
.gamma,
.epsilon,
.zeta {
  text-transform: normal;
  letter-spacing: normal;
  font-weight: bold;
  font-style: normal;
  font-family: Local-GT-Walsheim-Pro, Helvetica, Arial, sans-serif;
}
html.fonts-loaded .beta,
html.fonts-loaded .gamma,
html.fonts-loaded .epsilon,
html.fonts-loaded .zeta {
  font-family: GT-Walsheim-Pro, Helvetica, Arial, sans-serif;
}

.delta {
  text-transform: none;
  letter-spacing: normal;
  font-weight: normal;
  font-style: normal;
  font-family: Local-GT-Walsheim-Pro, Helvetica, Arial, sans-serif;
}
html.fonts-loaded .delta {
  font-family: GT-Walsheim-Pro, Helvetica, Arial, sans-serif;
}

.eta {
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: normal;
  font-style: normal;
  font-family: Local-GT-Walsheim-Pro, Helvetica, Arial, sans-serif;
}
html.fonts-loaded .eta {
  font-family: GT-Walsheim-Pro, Helvetica, Arial, sans-serif;
}

.iota,
.kappa {
  text-transform: uppercase;
  letter-spacing: normal;
  font-weight: bold;
  font-style: italic;
  font-family: Local-GT-Walsheim-Pro, Helvetica, Arial, sans-serif;
}
html.fonts-loaded .iota,
html.fonts-loaded .kappa {
  font-family: GT-Walsheim-Pro, Helvetica, Arial, sans-serif;
}

.theta {
  text-transform: none;
  letter-spacing: normal;
  font-weight: normal;
  font-style: normal;
  font-family: Georgia, Times New Roman, serif;
}

.longform > h1 {
  text-transform: normal;
  letter-spacing: -0.01em;
  font-weight: bold;
  font-style: italic;
  font-family: Local-GT-Walsheim-Pro, Helvetica, Arial, sans-serif;
}
html.fonts-loaded .longform > h1 {
  font-family: GT-Walsheim-Pro, Helvetica, Arial, sans-serif;
}
.longform > h2, .longform > h3, .longform > h5, .longform > h6 {
  text-transform: normal;
  letter-spacing: normal;
  font-weight: bold;
  font-style: normal;
  font-family: Local-GT-Walsheim-Pro, Helvetica, Arial, sans-serif;
}
html.fonts-loaded .longform > h2, html.fonts-loaded .longform > h3, html.fonts-loaded .longform > h5, html.fonts-loaded .longform > h6 {
  font-family: GT-Walsheim-Pro, Helvetica, Arial, sans-serif;
}
.longform > h4 {
  text-transform: none;
  letter-spacing: normal;
  font-weight: normal;
  font-style: normal;
  font-family: Local-GT-Walsheim-Pro, Helvetica, Arial, sans-serif;
}
html.fonts-loaded .longform > h4 {
  font-family: GT-Walsheim-Pro, Helvetica, Arial, sans-serif;
}

.dimmed {
  color: #999999;
}

.p,
p,
.intro,
p.intro,
.large,
p.large {
  font-weight: normal;
  letter-spacing: normal;
  text-transform: none;
  font-family: Georgia, Times New Roman, serif;
}

.small,
p.small,
.xsmall,
p.xsmall {
  font-weight: normal;
  letter-spacing: normal;
  text-transform: none;
  font-family: Local-GT-Walsheim-Pro, Helvetica, Arial, sans-serif;
}
html.fonts-loaded .small,
html.fonts-loaded p.small,
html.fonts-loaded .xsmall,
html.fonts-loaded p.xsmall {
  font-family: GT-Walsheim-Pro, Helvetica, Arial, sans-serif;
}

.center {
  text-align: center;
}

.longform > p + p {
  text-indent: 1.5em;
}

.inline-images > a {
  display: inline-block;
  margin-right: 1em;
  margin-bottom: 0.5em;
  width: 15%;
}
.inline-images > a:first-child {
  width: 50%;
  vertical-align: text-bottom;
}
@media screen and (min-width: 46.875em) {
  .inline-images > a:first-child {
    vertical-align: bottom;
  }
}
.inline-images > a > img {
  display: inline-block;
}

.bar-separated > span + span:before {
  margin: 0 0.75em;
  content: "| ";
}

blockquote, .blockquote {
  text-transform: normal;
  letter-spacing: normal;
  font-weight: bold;
  font-style: italic;
  font-family: Local-GT-Walsheim-Pro, Helvetica, Arial, sans-serif;
}
html.fonts-loaded blockquote, html.fonts-loaded .blockquote {
  font-family: GT-Walsheim-Pro, Helvetica, Arial, sans-serif;
}

a {
  color: #0164ad;
  text-decoration: none;
  transition: all 0.25s ease;
}
a:hover, a:focus {
  color: #ff8600;
  text-decoration: none;
}

a.link-has-arrow {
  background-repeat: no-repeat;
  background-image: url("/images/slideshow-arrow-right.svg");
  background-position: center right;
  background-size: 10%;
  padding-right: 0.9375rem;
}

.links-are-reversed a {
  color: #ff8600;
}
.links-are-reversed a:hover, .links-are-reversed a:focus {
  color: #0164ad;
}

.links-are-reversed.reverse-type a {
  color: #ff8600;
}
.links-are-reversed.reverse-type a:hover, .links-are-reversed.reverse-type a:focus {
  opacity: 0.8;
}

.links-are-rose.reverse-type a,
.links-are-rose a {
  color: #f55b87;
}
.links-are-rose.reverse-type a:hover, .links-are-rose.reverse-type a:focus,
.links-are-rose a:hover,
.links-are-rose a:focus {
  color: #f55b87;
}

.links-are-orange.reverse-type a,
.links-are-orange a {
  color: #ff8600;
}
.links-are-orange.reverse-type a:hover, .links-are-orange.reverse-type a:focus,
.links-are-orange a:hover,
.links-are-orange a:focus {
  color: rgba(255, 134, 0, 0.8);
}

.links-are-white.reverse-type a,
.links-are-white a {
  color: #ffffff;
}
.links-are-white.reverse-type a:hover, .links-are-white.reverse-type a:focus,
.links-are-white a:hover,
.links-are-white a:focus {
  color: #ffffff;
}

.longform > h1 > a, .longform > h2 > a, .longform > h3 > a, .longform > h4 > a, .longform > h5 > a, .longform > h6 > a {
  color: #0164ad;
}
.longform > h1 > a:focus, .longform > h1 > a:hover, .longform > h2 > a:focus, .longform > h2 > a:hover, .longform > h3 > a:focus, .longform > h3 > a:hover, .longform > h4 > a:focus, .longform > h4 > a:hover, .longform > h5 > a:focus, .longform > h5 > a:hover, .longform > h6 > a:focus, .longform > h6 > a:hover {
  color: #ff8600;
}

.longform ol {
  counter-reset: li;
  overflow: hidden;
}
.longform ol li > ol,
.longform ol li > ul {
  margin-top: 0.65625rem;
}
@media screen and (min-width: 59.375em) {
  .longform ol li > ol,
.longform ol li > ul {
    margin-top: 0.75rem;
  }
}
@media screen and (min-width: 87.5em) {
  .longform ol li > ol,
.longform ol li > ul {
    margin-top: 0.75rem;
  }
}
.longform ol p {
  font-family: GT-Walsheim-Pro, Helvetica, Arial, sans-serif;
}
.longform ol > li {
  position: relative;
  list-style: none;
  padding: 0 0 0 0.9375rem;
}
@media screen and (min-width: 59.375em) {
  .longform ol > li {
    padding: 0 0 0 1.5625rem;
  }
}
.longform ol > li:before {
  content: counter(li) ".";
  counter-increment: li;
  position: absolute;
  top: 0;
  left: 0;
  color: #0164ad;
}
.longform ol > li + li {
  margin-top: 0.65625rem;
}
@media screen and (min-width: 59.375em) {
  .longform ol > li + li {
    margin-top: 0.75rem;
  }
}
@media screen and (min-width: 87.5em) {
  .longform ol > li + li {
    margin-top: 0.75rem;
  }
}

.longform ul {
  overflow: hidden;
}
.longform ul p {
  font-family: GT-Walsheim-Pro, Helvetica, Arial, sans-serif;
}
.longform ul li {
  position: relative;
  padding: 0 0 0.625rem 0.9375rem;
}
@media screen and (min-width: 59.375em) {
  .longform ul li {
    padding: 0 0 0.625rem 1.5625rem;
  }
}
.longform ul li:before {
  content: "○";
  position: absolute;
  top: 0;
  left: 0;
  color: #0164ad;
  font-family: Georgia, Times New Roman, serif;
}
.longform ul li.remove-bullet:before {
  display: none;
}
.longform ul li ul {
  margin-top: 0.625rem;
}
@media print {
  .longform ul li {
    list-style-type: disc;
  }
}

.inline-links li {
  display: inline-block;
  margin-right: 1em;
}
.inline-links li:last-child {
  margin-right: 0;
}

dt + dd {
  margin-top: 0;
}

dt {
  font-weight: bold;
}

dd {
  margin-left: 0.5em;
}

.toggle-list {
  display: none;
}
.longform > .toggle-list {
  margin-top: 0;
}

.toggle-list-trigger a:before {
  content: "More...";
}
.toggle-list-trigger.toggle-list-open {
  display: none;
}

.horizontal-button-list a {
  display: block;
}
.horizontal-button-list a span {
  display: block;
  margin: 0;
}
.horizontal-button-list a span + span {
  color: #999999;
}
.horizontal-button-list li {
  border: 1px solid #d0d0d0;
  transition: background border-color 0.25s ease;
  width: 100%;
  margin-bottom: -1px;
}
@media screen and (min-width: 46.875em) {
  .horizontal-button-list li {
    border-left: none;
  }
  .horizontal-button-list li:first-child {
    border-left: 1px solid #d0d0d0;
  }
}
.horizontal-button-list li:last-child {
  margin-bottom: 0;
}
.horizontal-button-list li a {
  padding: 0.65625rem 1.3125rem;
}
@media screen and (min-width: 31.25em) {
  .horizontal-button-list li {
    display: inline-block;
    margin-right: 2em;
  }
  .horizontal-button-list li:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 46.875em) {
  .flexbox .horizontal-button-list {
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: center;
    align-content: flex-start;
  }
  .flexbox .horizontal-button-list li {
    display: flex;
    align-items: stretch;
    margin: 0;
    width: auto;
  }
  .flexbox .horizontal-button-list li:hover {
    background: #343332;
    border-color: #343332;
  }
}
@media screen and (min-width: 46.875em) and (min-width: 59.375em) {
  .flexbox .horizontal-button-list a {
    padding: 0.75rem 1.5rem;
  }
}
@media screen and (min-width: 46.875em) {
  .flexbox .horizontal-button-list a span {
    transition: all 0.25s ease;
    position: relative;
    top: 0.625rem;
  }
}
@media screen and (min-width: 46.875em) {
  .flexbox .horizontal-button-list a span + span {
    position: relative;
    top: -0.625rem;
    opacity: 0;
    color: #d0d0d0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media screen and (min-width: 46.875em) {
  .flexbox .horizontal-button-list a:hover span {
    color: #ffffff;
    top: 0;
    opacity: 1;
  }
  .flexbox .horizontal-button-list a:hover span + span {
    color: #999999;
  }
}

@media screen and (min-width: 46.875em) {
  .no-flexbox .horizontal-button-list {
    width: 100%;
    display: block;
    float: left;
  }
  .no-flexbox .horizontal-button-list li {
    display: inline-block;
    width: auto;
    margin: 0 -0.1875rem;
  }
  .no-flexbox .quarterly-results__footer {
    clear: both;
    padding-top: 1.875rem;
  }
}

.horizontal-icon-list li {
  display: inline-block;
}
.horizontal-icon-list li + li {
  margin-left: 0.5em;
}
.horizontal-icon-list li a {
  display: block;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  overflow: hidden;
  display: block;
  height: 1.4375rem;
  width: 1.4375rem;
}

hr {
  border: none;
  border-top: 1px solid #282725;
}

.reverse-type > h1, .reverse-type > h2, .reverse-type > h3, .reverse-type > h4, .reverse-type > h5, .reverse-type > h6, .reverse-type > p, .reverse-type > span, .reverse-type > label, .reverse-type > ul > li {
  color: #ffffff;
}
.reverse-type > h1 > a, .reverse-type > h2 > a, .reverse-type > h3 > a, .reverse-type > h4 > a, .reverse-type > h5 > a, .reverse-type > h6 > a, .reverse-type > p > a, .reverse-type > span > a, .reverse-type > label > a, .reverse-type > ul > li > a {
  color: #ffffff;
}

.table caption {
  caption-side: bottom;
}
.table caption,
.table th,
.table td {
  font-weight: normal;
  padding: 0.4375rem;
  text-align: left;
}
@media screen and (min-width: 59.375em) {
  .table caption,
.table th,
.table td {
    padding: 0.5rem;
  }
}
.table--large, .table--large table {
  width: 100%;
  border-collapse: separate;
  border-radius: 6px;
  table-layout: fixed;
}
.table--large thead tr th:first-child, .table--large table thead tr th:first-child {
  border-top-left-radius: 0.5em;
}
.table--large thead tr th:last-child, .table--large table thead tr th:last-child {
  border-top-right-radius: 0.5em;
}
.table--large thead tr th, .table--large table thead tr th {
  background-color: #282725;
  color: #ffffff;
}
.table--large tbody tr td, .table--large table tbody tr td {
  border-bottom: 2px solid #d0d0d0;
  word-wrap: break-word;
  word-break: break-all;
}
.table--large tbody tr td:first-child, .table--large table tbody tr td:first-child {
  border-left: 2px solid #d0d0d0;
}
.table--large tbody tr td:last-child, .table--large table tbody tr td:last-child {
  border-right: 2px solid #d0d0d0;
}
.table--large tbody tr td + td, .table--large table tbody tr td + td {
  border-left: 2px solid #d0d0d0;
}
.table--large tbody tr:last-child, .table--large table tbody tr:last-child {
  border-bottom: 2px solid #d0d0d0;
}
.table--large tbody tr:last-child td, .table--large table tbody tr:last-child td {
  border-bottom: 2px solid #d0d0d0;
}
.table--large tbody tr:last-child td:first-child, .table--large table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 0.5em;
}
.table--large tbody tr:last-child td:last-child, .table--large table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 0.5em;
}
.table--small, .table--small table, .table--medium, .table--medium table {
  width: 100%;
  max-width: 25.625rem;
  border: 2px solid #d0d0d0;
  border-collapse: separate;
  border-radius: 0.5em;
  table-layout: fixed;
}
.table--small td, .table--small table td, .table--medium td, .table--medium table td {
  padding: 0.4375rem 0.65625rem;
  width: 50%;
  text-align: left;
}
@media screen and (min-width: 59.375em) {
  .table--small td, .table--small table td, .table--medium td, .table--medium table td {
    padding: 0.5rem 0.75rem;
  }
}
.table--small td + td, .table--small table td + td, .table--medium td + td, .table--medium table td + td {
  border-left: 2px solid #d0d0d0;
}
.table--medium, .table--medium table {
  max-width: none;
}

.nrnd .table--small {
  border-color: red;
}

.layout-support-section .table.table--large td {
  word-break: normal;
  vertical-align: top;
}

.error .hint {
  color: red;
}
.error select,
.error textarea,
.error input[type=text],
.error input[type=email],
.error input[type=number],
.error input[type=password],
.error input[type=search],
.error input[type=tel],
.error input[type=url],
.error input[type=month],
.error input[type=time],
.error input[type=week],
.error input[type=date],
.error input[type=datetime],
.error input[type=datetime-local] {
  border: 1px solid red;
}

.input-field label {
  font-size: 0.625rem;
  line-height: 1.2;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  letter-spacing: normal;
  font-weight: normal;
  font-style: normal;
  font-family: Local-GT-Walsheim-Pro, Helvetica, Arial, sans-serif;
}
@media screen and (min-width: 59.375em) {
  .input-field label {
    font-size: 0.875rem;
    line-height: 1.2857142857;
  }
}
@media screen and (min-width: 87.5em) {
  .input-field label {
    font-size: 0.875rem;
    line-height: 1.2857142857;
  }
}
@media print {
  .input-field label {
    font-size: 0.625rem;
    line-height: 2.4;
  }
}
html.fonts-loaded .input-field label {
  font-family: GT-Walsheim-Pro, Helvetica, Arial, sans-serif;
}
.input-field input[type=checkbox] + label,
.input-field input[type=radio] + label {
  text-transform: none;
  letter-spacing: normal;
  font-weight: normal;
  font-style: normal;
  vertical-align: middle;
}

.input--full {
  width: 100%;
}

select,
textarea,
input[type=text],
input[type=email],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type=url],
input[type=month],
input[type=time],
input[type=week],
input[type=date],
input[type=datetime],
input[type=datetime-local] {
  font-family: "Helvetica", sans-serif;
  height: 2.28125rem;
  padding: 0 0.75em;
  border: 0;
  color: #282725;
  background-color: #f1f1f1;
  transition: box-shadow 0.15s ease, border 0.15s ease;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  width: 100%;
  font-size: 0.75rem;
  line-height: 1.3333333333;
}
@media screen and (min-width: 59.375em) {
  select,
textarea,
input[type=text],
input[type=email],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type=url],
input[type=month],
input[type=time],
input[type=week],
input[type=date],
input[type=datetime],
input[type=datetime-local] {
    font-size: 0.9375rem;
    line-height: 1.3333333333;
  }
}
@media screen and (min-width: 87.5em) {
  select,
textarea,
input[type=text],
input[type=email],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type=url],
input[type=month],
input[type=time],
input[type=week],
input[type=date],
input[type=datetime],
input[type=datetime-local] {
    font-size: 0.9375rem;
    line-height: 1.3333333333;
  }
}
@media print {
  select,
textarea,
input[type=text],
input[type=email],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type=url],
input[type=month],
input[type=time],
input[type=week],
input[type=date],
input[type=datetime],
input[type=datetime-local] {
    font-size: 0.75rem;
    line-height: 1.3333333333;
  }
}
@media screen and (min-width: 59.375em) {
  select,
textarea,
input[type=text],
input[type=email],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type=url],
input[type=month],
input[type=time],
input[type=week],
input[type=date],
input[type=datetime],
input[type=datetime-local] {
    height: 2.375rem;
    padding: 0 0.375rem;
  }
}
@media screen and (min-width: 87.5em) {
  select,
textarea,
input[type=text],
input[type=email],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type=url],
input[type=month],
input[type=time],
input[type=week],
input[type=date],
input[type=datetime],
input[type=datetime-local] {
    height: 2.375rem;
    padding: 0 0.375rem;
  }
}
select:focus,
textarea:focus,
input[type=text]:focus,
input[type=email]:focus,
input[type=number]:focus,
input[type=password]:focus,
input[type=search]:focus,
input[type=tel]:focus,
input[type=url]:focus,
input[type=month]:focus,
input[type=time]:focus,
input[type=week]:focus,
input[type=date]:focus,
input[type=datetime]:focus,
input[type=datetime-local]:focus {
  outline: none;
  border-color: rgba(119, 119, 119, 0.32);
  box-shadow: 1px 1px 3px 0px rgba(119, 119, 119, 0.32);
}
select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
input[type=text]::-webkit-input-placeholder,
input[type=email]::-webkit-input-placeholder,
input[type=number]::-webkit-input-placeholder,
input[type=password]::-webkit-input-placeholder,
input[type=search]::-webkit-input-placeholder,
input[type=tel]::-webkit-input-placeholder,
input[type=url]::-webkit-input-placeholder,
input[type=month]::-webkit-input-placeholder,
input[type=time]::-webkit-input-placeholder,
input[type=week]::-webkit-input-placeholder,
input[type=date]::-webkit-input-placeholder,
input[type=datetime]::-webkit-input-placeholder,
input[type=datetime-local]::-webkit-input-placeholder {
  color: #999999;
}
select:-moz-placeholder,
textarea:-moz-placeholder,
input[type=text]:-moz-placeholder,
input[type=email]:-moz-placeholder,
input[type=number]:-moz-placeholder,
input[type=password]:-moz-placeholder,
input[type=search]:-moz-placeholder,
input[type=tel]:-moz-placeholder,
input[type=url]:-moz-placeholder,
input[type=month]:-moz-placeholder,
input[type=time]:-moz-placeholder,
input[type=week]:-moz-placeholder,
input[type=date]:-moz-placeholder,
input[type=datetime]:-moz-placeholder,
input[type=datetime-local]:-moz-placeholder { /* Firefox 18- */
  color: #999999;
}
select::-moz-placeholder,
textarea::-moz-placeholder,
input[type=text]::-moz-placeholder,
input[type=email]::-moz-placeholder,
input[type=number]::-moz-placeholder,
input[type=password]::-moz-placeholder,
input[type=search]::-moz-placeholder,
input[type=tel]::-moz-placeholder,
input[type=url]::-moz-placeholder,
input[type=month]::-moz-placeholder,
input[type=time]::-moz-placeholder,
input[type=week]::-moz-placeholder,
input[type=date]::-moz-placeholder,
input[type=datetime]::-moz-placeholder,
input[type=datetime-local]::-moz-placeholder { /* Firefox 19+ */
  color: #999999;
}
select:-ms-input-placeholder,
textarea:-ms-input-placeholder,
input[type=text]:-ms-input-placeholder,
input[type=email]:-ms-input-placeholder,
input[type=number]:-ms-input-placeholder,
input[type=password]:-ms-input-placeholder,
input[type=search]:-ms-input-placeholder,
input[type=tel]:-ms-input-placeholder,
input[type=url]:-ms-input-placeholder,
input[type=month]:-ms-input-placeholder,
input[type=time]:-ms-input-placeholder,
input[type=week]:-ms-input-placeholder,
input[type=date]:-ms-input-placeholder,
input[type=datetime]:-ms-input-placeholder,
input[type=datetime-local]:-ms-input-placeholder {
  color: #999999;
}
select.placeholder-support,
textarea.placeholder-support,
input[type=text].placeholder-support,
input[type=email].placeholder-support,
input[type=number].placeholder-support,
input[type=password].placeholder-support,
input[type=search].placeholder-support,
input[type=tel].placeholder-support,
input[type=url].placeholder-support,
input[type=month].placeholder-support,
input[type=time].placeholder-support,
input[type=week].placeholder-support,
input[type=date].placeholder-support,
input[type=datetime].placeholder-support,
input[type=datetime-local].placeholder-support {
  color: #999999;
}
select.input-border,
textarea.input-border,
input[type=text].input-border,
input[type=email].input-border,
input[type=number].input-border,
input[type=password].input-border,
input[type=search].input-border,
input[type=tel].input-border,
input[type=url].input-border,
input[type=month].input-border,
input[type=time].input-border,
input[type=week].input-border,
input[type=date].input-border,
input[type=datetime].input-border,
input[type=datetime-local].input-border {
  border: 1px solid #d0d0d0;
  border-radius: 2px;
  background: #ffffff;
}
select.input-small,
textarea.input-small,
input[type=text].input-small,
input[type=email].input-small,
input[type=number].input-small,
input[type=password].input-small,
input[type=search].input-small,
input[type=tel].input-small,
input[type=url].input-small,
input[type=month].input-small,
input[type=time].input-small,
input[type=week].input-small,
input[type=date].input-small,
input[type=datetime].input-small,
input[type=datetime-local].input-small {
  display: inline-block;
  max-width: 300px;
}

.input-small + input[type=submit],
.input-small + button {
  margin-top: 0.65625rem;
}
@media screen and (min-width: 59.375em) {
  .input-small + input[type=submit],
.input-small + button {
    margin-top: 0.75rem;
  }
}
@media screen and (min-width: 87.5em) {
  .input-small + input[type=submit],
.input-small + button {
    margin-top: 0.75rem;
  }
}

select,
textarea {
  background-color: #ffffff;
  border: 1px solid #282725;
}

input[type=search] {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}
input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-results-button, input[type=search]::-webkit-search-decoration, input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

input[type=checkbox],
input[type=radio] {
  -webkit-appearance: none;
  background-color: #ffffff;
  border: 1px solid #282725;
  border-radius: 0;
  width: 0.9375rem;
  height: 0.9375rem;
  transition: all 0.25s ease;
}
input[type=checkbox]:checked,
input[type=radio]:checked {
  border: 1px solid #0164ad;
  background-color: #0164ad;
}

input[type=radio] {
  border-radius: 100%;
}

input[type=month],
input[type=time],
input[type=week] {
  font-family: monospace;
}

select[disabled],
textarea[disabled],
input[type=text][disabled],
input[type=email][disabled],
input[type=number][disabled],
input[type=password][disabled],
input[type=search][disabled],
input[type=tel][disabled],
input[type=url][disabled],
input[type=month][disabled],
input[type=time][disabled],
input[type=week][disabled],
input[type=date][disabled],
input[type=datetime][disabled],
input[type=datetime-local][disabled] {
  background: silver;
  color: lightgray;
}

textarea,
select[size],
select[multiple] {
  height: auto;
}

select[size="0"],
select[size="1"] {
  height: 2.5em;
}

input[type=checkbox],
input[type=radio] {
  vertical-align: middle;
}
input[type=checkbox] + label,
input[type=radio] + label {
  display: inline-block;
  margin-top: 0;
  margin-left: 0.25em;
}

.checkboxes > div {
  position: relative;
  padding-left: 1.25rem;
}
.checkboxes input[type=checkbox],
.checkboxes input[type=radio] {
  position: absolute;
  top: 0.1875rem;
  left: 0;
}
.checkboxes input[type=checkbox] + label,
.checkboxes input[type=radio] + label {
  display: block;
}

label {
  display: block;
  font-family: Local-GT-Walsheim-Pro, Helvetica, Arial, sans-serif;
}
html.fonts-loaded label {
  font-family: GT-Walsheim-Pro, Helvetica, Arial, sans-serif;
}
label em:before {
  content: "*";
  color: red;
}
label em span {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
label + * {
  margin-top: 0.65625rem;
}
@media screen and (min-width: 59.375em) {
  label + * {
    margin-top: 0.75rem;
  }
}
@media screen and (min-width: 87.5em) {
  label + * {
    margin-top: 0.75rem;
  }
}
.error label {
  color: red;
}

button,
.button,
.button-reverse,
input[type=reset],
input[type=submit],
input[type=button] {
  -webkit-appearance: none;
  -moz-apearance: none;
  appearance: none;
  border-radius: 0;
  -webkit-background-clip: padding;
  -moz-background-clip: padding;
  background-clip: padding-box;
  background: #0164ad;
  border: 1px solid #0164ad;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  overflow: visible;
  vertical-align: top;
  padding: 0.5rem 0.65625rem;
  margin: 0;
  transition: color 0.25s ease, background-color 0.25s ease, border-color 0.25s ease;
  min-height: 2.28125rem;
  font-size: 0.875rem;
  line-height: 1.3571428571;
  font-weight: bold;
  letter-spacing: normal;
  text-transform: none;
  font-family: Local-GT-Walsheim-Pro, Helvetica, Arial, sans-serif;
}
@media screen and (min-width: 59.375em) {
  button,
.button,
.button-reverse,
input[type=reset],
input[type=submit],
input[type=button] {
    font-size: 1.125rem;
    line-height: 1.3333333333;
  }
}
@media screen and (min-width: 87.5em) {
  button,
.button,
.button-reverse,
input[type=reset],
input[type=submit],
input[type=button] {
    font-size: 1.125rem;
    line-height: 1.3333333333;
  }
}
@media print {
  button,
.button,
.button-reverse,
input[type=reset],
input[type=submit],
input[type=button] {
    font-size: 0.875rem;
    line-height: 1.3571428571;
  }
}
html.fonts-loaded button,
html.fonts-loaded .button,
html.fonts-loaded .button-reverse,
html.fonts-loaded input[type=reset],
html.fonts-loaded input[type=submit],
html.fonts-loaded input[type=button] {
  font-family: GT-Walsheim-Pro, Helvetica, Arial, sans-serif;
}
button:focus, button:hover,
.button:focus,
.button:hover,
.button-reverse:focus,
.button-reverse:hover,
input[type=reset]:focus,
input[type=reset]:hover,
input[type=submit]:focus,
input[type=submit]:hover,
input[type=button]:focus,
input[type=button]:hover {
  color: #ffffff;
  background-color: #ff8600;
  border-color: #ff8600;
}
@media screen and (min-width: 59.375em) {
  button,
.button,
.button-reverse,
input[type=reset],
input[type=submit],
input[type=button] {
    padding: 0.375rem 0.75rem;
  }
}
@media screen and (min-width: 87.5em) {
  button,
.button,
.button-reverse,
input[type=reset],
input[type=submit],
input[type=button] {
    padding: 0.375rem 0.75rem;
  }
}

.button-reverse {
  color: #0164ad;
  background: #ffffff;
  border-color: #ffffff;
}
.button-reverse:focus, .button-reverse:hover {
  color: #ff8600;
  background-color: #ffffff;
  border-color: #ffffff;
}

.button-privacy-policy {
  color: #ffffff;
  background-color: transparent;
  border: none;
}
.button-privacy-policy:focus, .button-privacy-policy:hover {
  color: #ff8600;
  background-color: transparent;
}

#cookie-accept-btn {
  align-self: center;
}
@media screen and (min-width: 18.75em) {
  #cookie-accept-btn {
    margin-right: 3em;
  }
}
@media screen and (min-width: 25em) {
  #cookie-accept-btn {
    margin-right: 8em;
  }
}

.accordion-body {
  display: none;
  max-width: 40em;
}
.accordion-default-open .accordion-body {
  display: block;
}
.accordion-body .inner {
  margin-top: 0.65625rem;
}
@media screen and (min-width: 59.375em) {
  .accordion-body .inner {
    margin-top: 0.75rem;
  }
}
@media screen and (min-width: 87.5em) {
  .accordion-body .inner {
    margin-top: 0.75rem;
  }
}
.accordion-body div.longform li {
  font-weight: normal;
  font-family: GT-Walsheim-Pro, Helvetica, Arial, sans-serif;
  font-size: 1rem;
}

.accordion-title a {
  border-bottom: 1px dashed #282725;
  color: #282725;
}
.accordion-title a:before {
  content: "";
  display: inline-block;
  width: 0.9375rem;
  height: 0.9375rem;
  margin-right: 0.625rem;
  background: url("/images/plus.svg");
}
.psearch-ui .accordion-title a:before {
  float: right;
  margin-top: 3px;
  margin-right: 0px;
  margin-left: 0.625rem;
}
.accordion-title a.accordion-open:before {
  background: url("/images/minus.svg");
}

.accordion-toggle a {
  border-bottom: 1px dashed #282725;
  color: #282725;
}
.accordion-toggle a:before {
  content: "Show";
  display: inline-block;
  line-height: 1;
  height: 0.9375rem;
  padding-left: 1.5625rem;
  background: url("/images/plus.svg") left center no-repeat;
}
@media screen and (min-width: 59.375em) {
  .accordion-toggle a:before {
    line-height: 0.6;
  }
}
.accordion-toggle a.accordion-open:before {
  content: "Hide";
  background: url("/images/minus.svg") left center no-repeat;
}

button, label {
  font-family: GT-Walsheim-Pro, Helvetica, Arial, sans-serif;
  cursor: inherit;
}

button[disabled] {
  background: silver;
  border-color: silver;
  color: lightgray;
}

button:not([disabled]):hover {
  cursor: pointer;
}

.cursor-progress,
.cursor-progress *,
.cursor-progress label {
  cursor: progress;
}
`;
import { LitElement, html, css } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { Ref, createRef, ref } from 'lit/directives/ref.js';
import { classMap } from 'lit/directives/class-map.js';

import { Eula } from '@model/Eula';
import { baseStyles } from '../styles';
import { dialogStyles } from './dialog-styles';

function convertStringToHtml(str: string) {
  let parser = new DOMParser();
  let doc = parser.parseFromString(str, 'text/html');
  return doc.body;
}

@customElement('eula-dialog')
export class EulaDialog extends LitElement {
  static styles = [
    baseStyles,
    dialogStyles,
    css`
      dialog {
        padding-top: 0;
      }

      #eulaHeader {
        position: sticky;
        top: 0;
        background: white;
        padding: 0.5rem 0;
        border-bottom: 1px solid black;
      }
    `,
  ];

  dialogRef: Ref<HTMLDialogElement> = createRef();

  @state()
  inProgress: boolean = false;

  @state()
  currentEulaIndex: number = 0;

  @property({ attribute: false })
  eulas?: Eula[];

  @property()
  acceptCallback: (eula: Eula) => Promise<void> = Promise.resolve;

  get currentEula(): Eula | undefined {
    if (this.eulas === undefined) {
      return undefined;
    }

    return this.eulas[this.currentEulaIndex];
  }

  open() {
    this.dialogRef.value?.showModal();
    this.dialogRef.value?.scrollTo(0, 0);
  }

  close() {
    this.dialogRef.value?.close();
  }

  async onAccept() {
    if (this.currentEula === undefined) {
      throw new ReferenceError('this.currentEula is undefined');
    }

    this.inProgress = true;
    try {
      await this.acceptCallback(this.currentEula);
      this.currentEulaIndex++;
      this.dialogRef.value?.scrollTo(0, 0);
      if (this.currentEulaIndex === this.eulas?.length) {
        this.dispatchEvent(new Event('accepted'));
      }
    } finally {
      this.inProgress = false;
    }
  }

  render() {
    return html`
      <dialog
        id="eulaDialog"
        ${ref(this.dialogRef)}
        class=${classMap({ 'cursor-progress': this.inProgress })}
      >
        <h3 id="eulaHeader" tabindex="0">
          End User License Agreement (${this.currentEulaIndex + 1} of
          ${this.eulas?.length})
        </h3>
        <div>${convertStringToHtml(this.currentEula?.Content ?? '')}</div>
        <div>
          <button
            id="cancelButton"
            @click=${this.close}
            ?disabled=${this.inProgress}
          >
            Cancel
          </button>
          <button
            id="acceptButton"
            @click=${this.onAccept}
            ?disabled=${this.inProgress}
          >
            Accept
          </button>
        </div>
      </dialog>
    `;
  }
}

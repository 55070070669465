import { getFlexeraToken } from './http';
import { FlexeraSession } from '@model/FlexeraSession';

export type FlexeraTokenResponse = {
  token: string;
  expires: number;
};

let _flexeraSession: FlexeraSession | undefined;
let _expires: number | undefined;

const FLEXERA_SESSION_STORAGE_KEY = 'cirrus-srp-session';

// Recover token from localStorage
const storedSession = localStorage.getItem(FLEXERA_SESSION_STORAGE_KEY);
if (storedSession !== null) {
  const session = JSON.parse(storedSession) as FlexeraSession &
    FlexeraTokenResponse;
  _flexeraSession = {
    orgId: session.orgId,
    token: session.token,
  };
  _expires = session.expires;
}

function isExpired(): boolean {
  if (_expires === undefined) {
    return true;
  }

  // If the token is just about to expire, we should consider it expired.
  const currentTime = Math.floor(Date.now() / 1000);
  if (_expires - currentTime < 30) {
    return true;
  }

  return false;
}

export async function getFlexeraSession(): Promise<FlexeraSession> {
  if (_flexeraSession === undefined || isExpired()) {
    await refreshFlexeraSession();
  }
  if (_flexeraSession === undefined) {
    return Promise.reject('Could not refresh flexera session');
  } else {
    return _flexeraSession;
  }
}

export function hasFlexeraSession(): boolean {
  return _flexeraSession !== undefined;
}

export async function refreshFlexeraSession() {
  const tokenResponse = await getFlexeraToken();
  // @ts-ignore esbuild macro
  const orgId = FNO_ORG_ID;

  _expires = tokenResponse.expires;
  _flexeraSession = { orgId, token: tokenResponse.token };
  localStorage.setItem(
    FLEXERA_SESSION_STORAGE_KEY,
    JSON.stringify({ ..._flexeraSession, expires: _expires })
  );
}

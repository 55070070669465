import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { when } from 'lit/directives/when.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { map } from 'lit/directives/map.js';

import { DownloadableFileList } from '@wsdl-types/esdservice';
import { Product } from '@model/Product';
import { baseStyles } from '../styles';

// Based on https://gist.github.com/zentala/1e6f72438796d74531803cc3833c039c
// https://en.wikipedia.org/wiki/Byte#Multiple-byte_units
function formatBytes(size: number, decimal: number = 2): string {
  if (size == 0) return '0 B';
  var k = 1000, // we're using metric, i.e. decimal
    sizes = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(size) / Math.log(k));
  return parseFloat((size / Math.pow(k, i)).toFixed(decimal)) + ' ' + sizes[i];
}

@customElement('product-info')
export class ProductInfo extends LitElement {
  static styles = [
    baseStyles,
    css`
      #description {
        display: block;
        margin-top: 0.25rem;
        white-space: break-spaces;
      }

      .file-list {
        font-size: unset;
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        margin-top: 1rem;
      }

      .file-list-item {
        align-items: baseline;
        display: flex;
        gap: 0.5rem;
      }

      .file-list-item a {
        font-weight: bold;
      }
    `,
  ];

  @property({ type: Object })
  product?: Product;

  @property({ type: Array })
  downloadableFiles: DownloadableFileList[] = [];

  render() {
    if (this.product === undefined) {
      return html`<p>No product given</p>`;
    }

    return html`
      <strong>
        ${this.product.name.replaceAll('_', ' ')}
        ${when(
          this.product?.latestPackage.ShortVersion,
          () => html`<em>v${this.product?.latestPackage.ShortVersion}</em>`
        )}
      </strong>
      <span id="description">${this.product.description}</span>
      ${when(
        this.downloadableFiles.length > 0,
        () => html`
          <ul class="file-list">
            ${map(
              this.downloadableFiles,
              (f) => html`
                <li class="file-list-item">
                  <a href=${ifDefined(f.DownloadURL)} target="_blank"
                    >${f.FileName}</a
                  >
                  (${formatBytes(Number(f.CompressedSize))})
                </li>
              `
            )}
          </ul>
        `
      )}
    `;
  }
}

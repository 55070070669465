import { css } from 'lit';

export const dialogStyles = css`
  dialog {
    max-width: 80ch;
    padding: 1rem;
    border: none;
    font-family: GT-Walsheim-Pro;
  }

  dialog::backdrop {
    background: rgba(0, 0, 0, 0.65);
  }
`;

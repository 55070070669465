import { UserStatus } from '@okta/okta-sdk-nodejs';

import { Product } from '@model/Product';
import { Eula } from '@model/Eula';
import { DownloadRequest } from '@model/DownloadRequest';
import { CreateUserRequest } from '@model/CreateUserRequest';
import { DownloadableFileList } from '@wsdl-types/esdservice';
import { getFlexeraSession, FlexeraTokenResponse } from './flexera-session';
import { SrpError } from './SrpError';

// @ts-ignore: esbuild define macro
const appUrl = APP_URL || '';

async function getFlexeraHeaders() {
  const flexeraSession = await getFlexeraSession();
  return {
    'x-fno-org-id': flexeraSession.orgId,
    'x-fno-token': flexeraSession.token,
  };
}

export async function getProductAsync(name: string): Promise<Product> {
  return fetch(`${appUrl}/product/${name}`).then((response) => {
    if (response.status === 200) {
      return response.json();
    } else {
      return Promise.reject(new SrpError(`Could not find product '${name}'.`));
    }
  });
}

export async function getFlexeraToken(): Promise<FlexeraTokenResponse> {
  return fetch(`${appUrl}/fno-token`, {
    credentials: 'include',
  }).then((response) => {
    if (response.status === 200) {
      return response.json();
    } else {
      return Promise.reject(new SrpError('Failed to get FNO token.'));
    }
  });
}

export async function getEulasAsync(packageIds: string[]): Promise<Eula[]> {
  const queryParams = packageIds.map((id) => `packageIds[]=${id}`).join('&');
  return fetch(`${appUrl}/eulas?${queryParams}`, {
    credentials: 'include',
    headers: await getFlexeraHeaders(),
  }).then((response) => {
    if (response.status === 200) {
      return response.json();
    } else {
      return Promise.reject(new SrpError('Could not find EULA.'));
    }
  });
}

export async function acceptEulaAsync(packageId: string): Promise<void> {
  return fetch(`${appUrl}/accept`, {
    method: 'POST',
    body: JSON.stringify({ packageId }),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      ...(await getFlexeraHeaders()),
    },
  }).then((response) => {
    if (response.status !== 200) {
      return Promise.reject(new SrpError('Could not accept EULA.'));
    }
  });
}

export async function getDownloadableFiles(
  downloadRequests: DownloadRequest[]
): Promise<{ files: DownloadableFileList[]; unacceptedPackages: string[] }> {
  return fetch(`${appUrl}/download`, {
    method: 'POST',
    body: JSON.stringify(downloadRequests),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      ...(await getFlexeraHeaders()),
    },
  }).then((response) => {
    if (response.status === 200) {
      return response.json();
    } else if (response.status === 204) {
      return { files: [], unacceptedPackages: [] };
    } else {
      return Promise.reject(new SrpError('Could not get download links.'));
    }
  });
}

export type GetFileQuery = { packageId: string; systemFileId: number };

export function getFileUrl({ packageId, systemFileId }: GetFileQuery): string {
  return `${appUrl}/file-stream?packageId=${encodeURIComponent(
    packageId
  )}&systemFileId=${systemFileId}`;
}

export async function getFileStream(
  query: GetFileQuery,
  onSuccess: (response: any) => void,
  onProgress: (e: ProgressEvent) => void,
  onFailure?: Function
) {
  const req = new XMLHttpRequest();
  const url = getFileUrl(query);

  req.withCredentials = true;
  req.responseType = 'blob';
  req.open('get', url, true);

  for (const [key, value] of Object.entries(await getFlexeraHeaders())) {
    req.setRequestHeader(key, value);
  }

  req.onreadystatechange = function () {
    if (this.readyState === 4) {
      if (this.status === 200) {
        onSuccess(this.response);
      } else if (onFailure !== undefined) {
        onFailure();
      }
    }
  };
  req.onprogress = onProgress;
  req.send();
}

export async function createUser(
  user: CreateUserRequest
): Promise<UserStatus | 'CREATED'> {
  return fetch(`${appUrl}/user`, {
    method: 'POST',
    body: JSON.stringify(user),
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  }).then(async (response) => {
    if (response.status === 201) {
      return 'CREATED';
    } else if (response.status === 204) {
      return response.headers.get('x-okta-user-status') as UserStatus;
    } else {
      return Promise.reject(new SrpError('Could not create user account.'));
    }
  });
}

export async function activate(userId: string) {
  return fetch(`${appUrl}/activate`, {
    method: 'POST',
    body: JSON.stringify({ userId }),
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  }).then((response) => {
    if (response.status !== 200) {
      return Promise.reject(new SrpError('Could not reactivate user.'));
    }
  });
}

export function signIn(redirectUrl: string) {
  window.location.assign(`${appUrl}/login?redirectUrl=${btoa(redirectUrl)}`);
}

export async function isLoggedIn(): Promise<{ authenticated: boolean }> {
  return fetch(`${appUrl}/loggedIn`, {
    credentials: 'include',
  }).then((response) => {
    if (response.status === 200) {
      return response.json();
    } else {
      return Promise.reject(
        new SrpError('Could not determine authentication status.')
      );
    }
  });
}

import { ReactiveController, ReactiveControllerHost } from 'lit';
import { isLoggedIn } from '../http';

export class OktaController implements ReactiveController {
  host: ReactiveControllerHost;

  errorCallback: (e: Error) => void;

  hasLoaded: boolean = false;

  state: string | undefined;

  authenticated: boolean = false;

  async hostConnected() {
    try {
      const { authenticated } = await isLoggedIn();
      this.authenticated = authenticated;
    } catch (e) {
      this.errorCallback(e as Error);
    } finally {
      this.hasLoaded = true;
      this.host.requestUpdate();
    }
  }

  async isAuthenticated(): Promise<boolean> {
    try {
      const { authenticated } = await isLoggedIn();
      return authenticated;
    } catch (e) {
      this.errorCallback(e as Error);
      return false;
    }
  }

  constructor(host: ReactiveControllerHost, errorCallback: (e: Error) => void) {
    this.errorCallback = errorCallback;
    (this.host = host).addController(this);
  }
}

import { ReactiveController, ReactiveControllerHost } from 'lit';

import {
  getEulasAsync,
  getProductAsync,
  acceptEulaAsync,
  getDownloadableFiles,
} from '../http';
import { SrpError } from '../SrpError';
import { Product } from '@model/Product';
import { Eula } from '@model/Eula';
import { DownloadRequest } from '@model/DownloadRequest';
import { DownloadableFileList as DownloadableFile } from '@wsdl-types/esdservice';

const LINK_LIFESPAN = 1800000;

export class FlexeraController implements ReactiveController {
  host: ReactiveControllerHost;

  errorCallback: (e: Error) => void;

  mainProduct?: Product;

  get hasLoaded(): boolean {
    return this.mainProduct !== undefined;
  }

  downloadRequest?: DownloadRequest;

  eulas?: Eula[];

  downloadableFiles: DownloadableFile[] = [];

  unacceptedPackages: string[] = [];

  hostConnected() {}

  constructor(host: ReactiveControllerHost, errorCallback: (e: Error) => void) {
    this.errorCallback = errorCallback;
    (this.host = host).addController(this);
  }

  async loadProduct(name: string) {
    try {
      this.mainProduct = await getProductAsync(name);
      this.host.requestUpdate();
    } catch (e) {
      this.errorCallback(e as Error);
      return Promise.reject(e);
    }
  }

  async loadEulas(pkgIds: string[]) {
    try {
      this.eulas = await getEulasAsync(pkgIds);
      this.host.requestUpdate();
    } catch (e) {
      this.errorCallback(e as Error);
    }
  }

  async acceptEula(eula: Eula) {
    const packagesToAccept = eula.DownloadPackageIds.filter((pkgId) =>
      this.unacceptedPackages.includes(pkgId)
    );
    try {
      await Promise.all(packagesToAccept.map((id) => acceptEulaAsync(id)));
    } catch (e) {
      this.errorCallback(e as Error);
    }
  }

  async downloadFiles(downloadRequests: DownloadRequest[]) {
    try {
      const { files, unacceptedPackages } = await getDownloadableFiles(
        downloadRequests
      );
      files.forEach((f) => {
        if (!this.downloadableFiles.some((f2) => f.FileId === f2.FileId)) {
          this.downloadableFiles.push(f);
        }
      });
      this.unacceptedPackages = unacceptedPackages;

      // Start timer to warn users that the links expired.
      setTimeout(
        () =>
          this.errorCallback(
            new SrpError(
              'The download links have expired. Please refresh the page and accept the EULA(s) again to receive new download links.',
              true
            )
          ),
        LINK_LIFESPAN
      );
      this.host.requestUpdate();
    } catch (e) {
      this.errorCallback(e as Error);
    }
  }
}

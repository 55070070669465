import { LitElement, html, css } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { when } from 'lit/directives/when.js';
import { Ref, createRef, ref } from 'lit/directives/ref.js';
import { map } from 'lit/directives/map.js';
import { classMap } from 'lit/directives/class-map.js';

import { FlexeraController } from '../controllers/FlexeraController';
import { OktaController } from '../controllers/OktaController';
import { DownloadRequest } from '@model/DownloadRequest';
import { Product } from '@model/Product';
import { Eula } from '@model/Eula';
import { EulaDialog } from './eula-dialog';
import { RegistrationDialog } from './registration-dialog';
import { baseStyles } from '../styles';
import { signIn } from '../http';

import './product-info.ts';
import './eula-dialog.ts';
import './registration-dialog.ts';
import './error-report.ts';

@customElement('flexera-portal')
export class FlexeraPortal extends LitElement {
  static styles = [
    baseStyles,
    css`
      #container {
        padding-left: 2px;
      }

      .table--large {
        margin-top: 0.5rem;
        margin-left: -2px; /* The border collapse and spacing on the table causes a misalignment. */
        margin-bottom: 0.25rem;
      }

      #loading-placeholder {
        font-family: GT-Walsheim-Pro;
      }
    `,
  ];

  private flexeraController: FlexeraController = new FlexeraController(
    this,
    (e) => this.dispatchSrpError(e)
  );
  private oktaController: OktaController = new OktaController(this, (e) =>
    this.dispatchSrpError(e)
  );

  eulaDialogRef: Ref<EulaDialog> = createRef();
  registrationDialogRef: Ref<RegistrationDialog> = createRef();

  @property({ attribute: 'product-name' })
  productName?: string;

  @state()
  canDownloadFiles = false;

  @state()
  error?: Error;

  @state()
  inProgress: boolean = false;

  get allProducts(): Product[] {
    const mainProduct = this.flexeraController.mainProduct;
    if (mainProduct === undefined) {
      throw new ReferenceError(
        'this.flexeraController.mainProduct is undefined'
      );
    }
    return [mainProduct].concat(mainProduct.dependencies ?? []);
  }

  get downloadRequests() {
    return this.allProducts.map((p) => ({
      DownloadPackageID: p.latestPackage.DownloadPackageID,
      SystemFileIDs: p.files.map((f) => f.SystemFileId),
    }));
  }

  connectedCallback(): void {
    super.connectedCallback();

    // Validate the productName property
    if (this.productName === undefined || this.productName === '') {
      throw new ReferenceError(
        "this.productName is undefined or empty. Is the 'product-name' attribute set?"
      );
    }

    // Initialize product data
    this.flexeraController.loadProduct(this.productName).then(async () => {
      if (await this.oktaController.isAuthenticated()) {
        this.inProgress = true;
        await this.flexeraController.downloadFiles(this.downloadRequests);
        this.inProgress = false;
      }
    });

    // Setup error listener
    this.addEventListener('srp-error', (e) =>
      this.onSrpError(e as CustomEvent<Error>)
    );

    // Scroll to the SRP when specified
    if (window.location.hash === '#srp') {
      this.openAndScrollToPortal();
    }
  }

  dispatchSrpError(e: Error) {
    this.dispatchEvent(new CustomEvent('srp-error', { detail: e }));
  }

  onSrpError(e: CustomEvent<Error>) {
    this.error = e.detail;
    this.eulaDialogRef.value?.close();
    this.registrationDialogRef.value?.close();
  }

  /**
   * This function assumes that the portal is contained jQuery powered accordion element,
   * as it is understood to be when on the cirrus.com website.
   */
  openAndScrollToPortal() {
    try {
      // @ts-ignore
      if ($ !== undefined) {
        // Try to open the accordion that contains the SRP on cirrus.com
        const accordionBody = this.parentElement?.parentElement;
        if (accordionBody === undefined || accordionBody === null) {
          console.log('Could not find accordion body from flexera-portal');
          return;
        }

        // @ts-ignore
        $(accordionBody).slideToggle('fast');

        // Toggle the class on the link
        // @ts-ignore
        $(accordionBody).prev().children().toggleClass('accordion-open');

        // Scroll to portal
        // @ts-ignore
        $(document).ready(() => window.scrollTo(0, accordionBody.offsetTop));
      }
    } catch (e) {
      console.log("Expected to be able to use jQuery, but it didn't work...");
      console.error(e);
    }
  }

  onSignIn() {
    const url = window.location.origin
      .concat(window.location.pathname)
      .concat('#srp');
    signIn(url);
  }

  async onRegister() {
    this.registrationDialogRef.value?.open();
  }

  async onAcceptTerms() {
    this.inProgress = true;
    const packageIds = this.allProducts.map(
      (p) => p.latestPackage.DownloadPackageID
    );
    await this.flexeraController.loadEulas(packageIds);
    this.inProgress = false;
    this.eulaDialogRef.value?.open();
  }

  async onAccepted() {
    this.eulaDialogRef.value?.close();
    const downloadRequests: DownloadRequest[] = this.allProducts
      .filter((p) =>
        this.flexeraController.unacceptedPackages.includes(
          p.latestPackage.DownloadPackageID
        )
      )
      .map((p) => ({
        DownloadPackageID: p.latestPackage.DownloadPackageID,
        SystemFileIDs: p.files.map((f) => f.SystemFileId),
      }));
    this.inProgress = true;
    await this.flexeraController.downloadFiles(downloadRequests);
    this.canDownloadFiles = true;
    this.inProgress = false;
  }

  render() {
    try {
      if (this.error !== undefined) {
        return html`<error-report .error=${this.error}></error-report>`;
      }

      if (!this.flexeraController.hasLoaded || !this.oktaController.hasLoaded) {
        return html`<span id="loading-placeholder"
          >Loading ${this.productName}...</span
        >`;
      }

      return html`
        <div
          id="container"
          class="layout-support-section ${classMap({
            'cursor-progress': this.inProgress,
          })}"
        >
          ${when(
            this.flexeraController.mainProduct?.downloadInstructions !==
              undefined,
            () =>
              html`<span
                >${this.flexeraController.mainProduct
                  ?.downloadInstructions}</span
              >`
          )}
          <table class="table table--large">
            <thead>
              <tr>
                <th>Software Packages</th>
              </tr>
            </thead>
            <tbody>
              ${map(this.allProducts, (p) => {
                const downloadableFilesForProduct =
                  this.flexeraController.downloadableFiles
                    ?.filter((f) =>
                      p.files.some(
                        (f2) => f2.SystemFileId === Number(f.SystemFileId)
                      )
                    )
                    .sort((f1, f2) => {
                      if (
                        f1.FileName === undefined ||
                        f2.FileName === undefined
                      ) {
                        return 0;
                      }
                      if (f1.FileName < f2.FileName) {
                        return -1;
                      }
                      if (f1.FileName > f2.FileName) {
                        return 1;
                      }
                      return 0;
                    }) ?? [];
                return html`
                  <tr>
                    <td>
                      <product-info
                        .product=${p}
                        .downloadableFiles=${this.canDownloadFiles
                          ? downloadableFilesForProduct
                          : []}
                      ></product-info>
                    </td>
                  </tr>
                `;
              })}
            </tbody>
          </table>
          ${when(
            this.oktaController.authenticated && !this.canDownloadFiles,
            () => html`
              <button ?disabled=${this.inProgress} @click=${this.onAcceptTerms}>
                Accept Terms
              </button>
              <br />
              <span>Please accept terms to access files.</span>
            `
          )}
          ${when(
            !this.oktaController.authenticated,
            () => html`
              <button @click=${this.onSignIn}>Sign In</button>
              <button @click=${this.onRegister}>Register</button>
              <br />
              <span>Please sign in or register to access files.</span>
            `
          )}
        </div>
        <eula-dialog
          ${ref(this.eulaDialogRef)}
          .eulas=${this.flexeraController.eulas}
          .acceptCallback=${(e: Eula) => this.flexeraController.acceptEula(e)}
          @accepted=${this.onAccepted}
        ></eula-dialog>
        <registration-dialog
          ${ref(this.registrationDialogRef)}
        ></registration-dialog>
      `;
    } catch (error) {
      return html`<p>Error: ${error}</p>`;
    }
  }
}

import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { when } from 'lit/directives/when.js';

import { SrpError } from '../SrpError';
import { baseStyles } from '../styles';

@customElement('error-report')
export class ErrorReport extends LitElement {
  static styles = [
    baseStyles,
    css`
      #container {
        border: 2px solid red;
        display: block;
        width: 100%;
        padding: 0.5rem;
      }

      span {
        display: block;
      }
    `,
  ];

  @property({ attribute: false })
  error?: Error;

  render() {
    let isSrpError = false;
    let expected = false;

    if (this.error instanceof SrpError) {
      isSrpError = true;
      expected = this.error.expected;
    }

    return html`
      <div id="container">
        ${when(
          expected,
          () => html` <span>${this.error?.message}</span> `,
          () => html`
            <span>
              An unexpected error occured. Please refresh the page and try
              again.
            </span>
            <span>
              If the issue persists, then we most likely already know about it
              and are currently working to fix it. Thank you for your patience.
            </span>
            ${when(
              isSrpError,
              () =>
                html`<span
                  ><strong>Error:</strong> ${this.error?.message}</span
                >`
            )}
          `
        )}
      </div>
    `;
  }
}

import { CreateUserRequest } from '../model/CreateUserRequest';

export function makeCreateUserRequest(fd: FormData): CreateUserRequest {
  return {
    firstName: fd.get('firstname'),
    secondName: fd.get('secondname'),
    email: fd.get('email'),
    org: fd.get('organization'),
    role: fd.get('role'),
    applicationArea: fd.get('application'),
    country: fd.get('country'),
  } as CreateUserRequest;
}

// https://regexr.com/3e48o
export const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

export function validateCreateUserRequest(
  user: Partial<CreateUserRequest>
): string[] {
  if (
    !(
      Boolean(user.firstName) &&
      Boolean(user.secondName) &&
      Boolean(user.email) &&
      Boolean(user.org) &&
      Boolean(user.role) &&
      Boolean(user.applicationArea) &&
      Boolean(user.country)
    )
  ) {
    return ['All fields are required'];
  } else if (!emailRegex.test(user.email as string)) {
    return ['Invalid email address'];
  } else {
    return [];
  }
}
